import './Accounts.scss'
import React, {SyntheticEvent, useState} from 'react'
import {Card} from '../../components/Card/Card'
import {Page} from '../../components/Common/Page'
import {EntityManager} from '../../components/EntityManager/EntityManager'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import {OptimizelyFeature} from '@optimizely/react-sdk'
import {Redirect} from 'react-router'
import {get as _get} from 'lodash-es'
import {RemoteData} from '../../utils/RemoteData'
import {useDataPagination} from '../../utils/UseDataPagination'
import {Utility} from '../../utils/Utility'
import {graphql} from '@mirror/dataplane'
import {useMutation} from 'urql'
import {AccountQuery} from '@mirror/dataplane/build/graphql'

interface IComponentProps {}

const Accounts: React.FC<IComponentProps> = props => {
  const [, executeRemoveAccount] = useMutation(graphql.mutations.removeAccount)

  const listFormatter = {
    fields: [
      {
        label: 'Name',
        formatter: item => item.name
      },
      {
        label: 'Members',
        formatter: item => {
          const count = _get(item, 'members.items', []).length
          const additional = Utility.hasNext(item, 'members.next') ? '+' : ''

          const addMember = <PolyIcon className="ml-5 align-35" lensIcon="plus-circle" size={18} />

          return (
            <span
              onClick={(e: SyntheticEvent) => {
                e.stopPropagation()
                Utility.redirect(e, props, `/account/${item.id}#members`)
              }}
            >
              {count}
              {additional}
              {addMember}
            </span>
          )
        }
      },
      {
        label: 'Actions',
        className: 'showOnHover actions',
        formatter: item => {
          return (
            <>
              <button
                className="action"
                onClick={(e: SyntheticEvent) => {
                  e.stopPropagation()
                  Utility.redirect(e, props, `/account/${item.id}`)
                }}
              >
                <PolyIcon title="Edit" icon="edit" size="xxs" />
              </button>
              <button
                className="action warning"
                onClick={(e: SyntheticEvent) => {
                  e.stopPropagation()
                  RemoteData.handleRemoveItem(executeRemoveAccount, {id: item.id}, item.name, 'account', 'data.removeAccount').then(() => executeAccountsQuery())
                }}
              >
                <PolyIcon title="Delete" icon="trash" size="xxs" />
              </button>
            </>
          )
        }
      }
    ]
  }

  const nameSingular = 'Account'
  const namePlural = 'Accounts'

  const [accountsVariables, setAccountsVariables] = useState({
    limit: 500,
    cursor: undefined as undefined | string
  })
  const pagination = useDataPagination(graphql.queries.accounts, accountsVariables, setAccountsVariables, 'accounts.items', 'accounts.next')
  const {query: accountsQuery, executeQuery: executeAccountsQuery} = pagination

  const newItemButton = (
    <button
      className="button"
      onClick={(e: SyntheticEvent) => {
        Utility.redirect(e, props, '/account/new')
      }}
    >
      <PolyIcon className="mr-25" icon="add_people" size="sm" />
      Add Account
    </button>
  )

  return (
    <>
      <OptimizelyFeature feature="mono_mirror_manage_accounts_users">{isEnabled => !isEnabled && <Redirect to="/" />}</OptimizelyFeature>
      <Page>
        {/* left column */}
        {/* when more functionality exists on left column, consider creating a Card such as below */}
        {/*<Card className="device-left-col col-12 col-lg-3 auto left-col-sticky" index={1} contentClasses="p-1">*/}
        <div className="col-12 col-lg-3">{newItemButton}</div>
        {/* right side output column */}
        <div className="col-12 col-lg-9">
          {/* MEMBERS */}
          <Card className="device-section" index={2}>
            <EntityManager
              nameSingular={nameSingular}
              namePlural={namePlural}
              listHeader={
                <h3>
                  <PolyIcon className="mr-25" icon="people_group" size="sm" />
                  {namePlural}
                </h3>
              }
              // based on graphql.queries.accounts
              listQuery={accountsQuery}
              executeListQuery={executeAccountsQuery}
              listQueryAccessor="accounts"
              listFormatter={listFormatter}
              onClickRow={(e: SyntheticEvent, item: AccountQuery['account']) => {
                e.stopPropagation()
                Utility.redirect(e, props, `/account/${item.id}`)
              }}
              pagination={pagination}
            />
          </Card>
        </div>
      </Page>
    </>
  )
}

export {Accounts}
