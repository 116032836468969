import {ConnectedRouter} from 'connected-react-router'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import 'react-widgets/dist/css/react-widgets.css'
import {Provider} from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import {App} from './components/App/App'
import * as serviceWorker from './serviceWorker'
import configureStore, {history} from './store'

// @help https://github.com/date-fns/date-fns/blob/master/docs/esm.md,
// /esm (not /esm/fp) seems to be best tree-shaking option and
// is the preferred way to add functions for this code base
// e.g. import {addDays} from 'date-fns/esm'
import en from 'date-fns/esm/locale/en-US'
import dateFnsLocalizer from 'react-widgets-date-fns'

// localizer for react-widgets
dateFnsLocalizer({locales: {en: en}})

ReactDOM.render(
  <Provider store={configureStore()}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
    {/* Listener for toastr events. See Notification.tsx for defaults set for every notification. */}
    {/*
        // @ts-ignore */}
    <ReduxToastr getState={state => state.toastr} />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
