import React, {Component} from 'react'
// import {Query} from 'react-apollo'
import './Tenants.scss'
// import gql from 'graphql-tag'
import {Page} from '../../components/Common/Page'
// import {listDevices} from '../../graphql/queries'

class Tenants extends Component<any, any> {
  gqlFactory(props: any) {
    /*
    return (
      <Query query={gql(listDevices)} fetchPolicy="cache-and-network"
             variables={{filter: null, limit: 1000, nextToken: null}}>
        {({loading, error, data}) => {
          if (loading) return <>Loading</>
          if (error) {
            return <pre>Error condition: {JSON.stringify(error, null, 2)}</pre>
          }

          return <>TYPE: {props.type} {props.children(data[props.type])}</>
        }}
      </Query>
    )
   */
  }

  render() {
    /*
    const renderItems = ({filter = null, limit = 1000, nextToken = null}) => (
      <Query query={gql(listDevices)} fetchPolicy="cache-and-network" variables={{filter, limit, nextToken}}>
        {({loading, error, data}) => {
          if (loading) return <>Loading</>
          if (error) {
            return <pre>Error condition: {JSON.stringify(error, null, 2)}</pre>
          }

          return (
            <pre>
              {JSON.stringify(data, null, 2)}
            </pre>
          )
        }}
      </Query>
    )
    */

    // const QueryOutput = this.gqlFactory

    // next steps:
    // READ https://s3.amazonaws.com/apollo-docs-1.x/redux.html

    // then consider:
    // 1. integrate apollo with existing store, then be able to tap into data
    // OR 2. call useGetQuery reducer, which would spawn a reducer to get data and 'publish' data
    // use data in return below

    return (
      <Page title="Tenants">
        {/* QueryOutput is child, parent is .device-contents */}
        {/*
        <QueryOutput type="listDevices">
          {(data: any) => (
            <pre>
                  Data:
              {JSON.stringify(data, null, 2)}
                </pre>
          )}
        </QueryOutput>
        */}
      </Page>
    )
  }
}

export {Tenants}
