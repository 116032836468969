import React, {Component} from 'react'
import './Tabs.scss'
import {Utility} from '../../utils/Utility'

declare let window

// simple tab implementation based off https://medium.com/@diegocasmo/a-simple-react-tabs-component-47cac2cfbb5

interface IComponentProps {
  defaultActiveTabIndex?: number
  defaultProps?: any
  children?: any
}

export class Tabs extends Component<IComponentProps> {
  state: any

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTabIndex: this.getActiveTabIndex()
    }
    this.handleTabClick = this.handleTabClick.bind(this)
  }

  /**
   * Get active tab index, 0-indexed.
   *
   * For example, with two tabs and a URL of "/account#members"
   * and tab IDs of "profile" and "members", this function will return 1
   * for the second tab to be selected.
   */
  getActiveTabIndex = () => {
    const hash = Utility.getURLHash()
    let activeTabIndex = this.props.defaultActiveTabIndex as number | 0
    React.Children.map(this.props.children, (child, index) => {
      if (!child) {
        // disallow a null (e.g. hidden ReactElement) child to be the activeTabIndex
        if (activeTabIndex === index) {
          activeTabIndex++
        }

        return <></>
      }

      // set this tab to active if its `id` is in the URL hash, e.g. "/account#members"
      if (hash && hash === child.props.id) {
        activeTabIndex = index
      }
    })

    return activeTabIndex
  }

  handleTabClick(tabIndex) {
    this.setState({
      activeTabIndex: tabIndex
    })

    const newHash = this.props.children[tabIndex].props.id
    if (newHash) {
      window.history.pushState(null, '', `#${newHash}`)
    }
  }

  // Encapsulate <Tabs/> component API as props for <Tab/> children
  renderChildrenWithTabsApiAsProps() {
    return React.Children.map(this.props.children, (child, index) => {
      if (!child) {
        return <></>
      }

      return React.cloneElement(child, {
        onClick: this.handleTabClick,
        tabIndex: index,
        isActive: index === this.state.activeTabIndex
      })
    })
  }

  // Render current active tab content
  renderActiveTabContent() {
    const {children} = this.props
    const {activeTabIndex} = this.state
    if (children && children[activeTabIndex]) {
      return children[activeTabIndex].props.children
    }
  }

  render() {
    // filter out any null children elements, e.g. from {showElement && <Tab>Element</Tab>} when showElement is falsy
    const childrenLength = this.props.children.filter(child => !!child).length
    const ulClasses = ['tabs-nav', 'nav', 'navbar-nav', 'navbar-center', `tabs-nav-children-${childrenLength}`].join(
      ' '
    )

    return (
      <div className="tabs">
        <ul className={ulClasses}>{this.renderChildrenWithTabsApiAsProps()}</ul>
        <div className="tabs-active-content">{this.renderActiveTabContent()}</div>
      </div>
    )
  }
}

// @ts-ignore
Tabs.defaultProps = {
  defaultActiveTabIndex: 0
}
