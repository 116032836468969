import {ActionType} from 'typesafe-actions'
import * as menuActions from '../login/actions'

export enum MenuActionType {
  selectMenuItem = 'SELECT_MENU_ITEM'
}
export type MenuAction = ActionType<typeof menuActions>

export interface MenuItem {
  readonly id: string
  readonly name: string
  readonly url: string
  readonly subMenus: Array<MenuItem>
}

export interface MenuState {
  readonly items: Array<MenuItem>
  readonly selectedId: string
}
