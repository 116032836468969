import {createReducer} from 'typesafe-actions'
import {changePageSetting} from './actions'
import {cloneDeep as _cloneDeep, set as _set} from 'lodash-es'

const defaultSettingsState = {
  pages: {}
}

const SettingsReducer = createReducer(defaultSettingsState)
  // @example call props.changeSetting('device.search.macAddress', 'CA:78')
  .handleAction(changePageSetting, (state, action) => {
    const newState = {
      ...state,
      pages: _cloneDeep(state.pages)
    }

    // allows deep setting, e.g. payload of 'device.search.macAddress'
    _set(newState.pages, action.payload, action.meta)

    return newState
  })

export {SettingsReducer}
