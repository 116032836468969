import React from 'react'
import {get as _get, lowerCase as _lowerCase, startCase as _startCase} from 'lodash-es'
import {FormUtility} from '../../components/Form/FormUtility'
import SwitchInput from '../../components/Form/SwitchInput'
import CheckboxInput, {CheckboxInputOptions} from '../../components/Form/CheckboxInput'
import {RadioInput} from '../../components/Form/RadioInput'
import {Formik, Form} from 'formik'
import {useQuery} from '../../graphql/graphqlClient'
import {changeBusy} from '../../store/layout/actions'
import {RemoteData} from '../../utils/RemoteData'
import {Utility} from '../../utils/Utility'
import {AccountType, APIPlan} from '@mirror/dataplane/build/graphql'
import {useDispatch} from 'react-redux'
import {graphql} from '@mirror/dataplane'

interface IComponentProps {
  id: string | undefined
  isNew: boolean
  saveButton: any
  accountQuery: any
  executeAccountQuery: any
  executeUpdateAccountMutation: any
}

/**
 * Component to edit API form.  Note account creation just includes profile,
 * to simplify creation and have separate-able, multi-part forms.
 *
 * @param props
 * @constructor
 */
const AccountAPIForm: React.FC<IComponentProps> = props => {
  const [apiMetaQuery] = useQuery(graphql.queries.apiMeta, {id: props.id})
  const dispatch = useDispatch()

  dispatch(changeBusy(apiMetaQuery.fetching))

  // this object should match the graphql format as it is merged with it
  let apiForm = {
    // in formOverload function, this will update with actual account ID if this is an update
    id: undefined,
    // not a dropdown, but enough to trigger a warning if default type changes
    type: AccountType.PARTNER,
    api: {
      enabled: false,
      plan: APIPlan[Object.keys(APIPlan)[0]],
      scopes: []
    }
  }

  // inject (overload) values from remote data for form data
  if (!props.isNew && props.accountQuery.data) {
    apiForm = FormUtility.formOverload(apiForm, props.accountQuery.data.account)
  }

  // create options from APIPlan enum
  const apiPlanOptions = Object.keys(APIPlan).map(key => {
    const readable = _startCase(_lowerCase(key))

    return {
      label: <span className="button-list-text-sm">{readable}</span>,
      value: APIPlan[key]
    }
  })

  const APIMeta = _get(apiMetaQuery, 'data.apiMeta.scopes', [])
  // create scope options from apiMeta query
  const apiScopeOptions: CheckboxInputOptions = APIMeta.map(obj => {
    return {
      label: (
        <>
          {obj.name}
          <br />
          <span className="normal-weight">{obj.description}</span>
        </>
      ),
      value: obj.id
    }
  })

  return (
    <Formik
      initialValues={apiForm}
      onSubmit={async values => {
        RemoteData.handleCreateOrUpdateAccount(props.executeUpdateAccountMutation, values)
          // refresh device data
          .then(id => {
            if (props.isNew) {
              Utility.redirect(undefined, props, `/account/${id}`, 3000)
            } else {
              // refresh data
              props.executeAccountQuery()
            }
          })
      }}
    >
      {formikProps => {
        // currently unused: isSubmitting, setFieldValue
        const {values, errors, handleChange, handleSubmit} = formikProps
        const hasErrors = !!Object.keys(errors).length

        return (
          <Form onSubmit={handleSubmit}>
            <SwitchInput name="api.enabled" title="API Access" label="Enable" />
            <RadioInput
              name="api.plan"
              id="api.plan"
              title="Plan"
              value={values.api.plan}
              options={apiPlanOptions}
              handleChange={handleChange}
            />
            {/* TODO select all/none button */}
            <CheckboxInput name="api.scopes" title="Scopes" buttonStyling={true} options={apiScopeOptions} />
            {props.saveButton(hasErrors)}
          </Form>
        )
      }}
    </Formik>
  )
}

export {AccountAPIForm}
