import {createReducer} from 'typesafe-actions'
import {MenuItem, MenuState} from './types'
import {selectMenuItem} from './actions'

export function simpleItem(id: string, name: string): MenuItem {
  return {
    id: id,
    name: name,
    url: `/${id}`,
    subMenus: []
  }
}

const defaultMenuState: MenuState = {
  selectedId: 'actions',
  items: [
    simpleItem('actions', 'Actions'),
    {
      id: 'devices',
      name: 'Devices',
      url: '/devices',
      subMenus: [
        simpleItem('devices/health', 'Health'),
        simpleItem('devices/usage', 'Usage'),
        simpleItem('devices/manage', 'Manage')
      ]
    },
    simpleItem('rooms', 'Rooms')
  ]
}

const menuReducer = createReducer(defaultMenuState).handleAction(selectMenuItem, (state, action) => {
  return {
    ...state,
    selectedId: action.payload
  }
})
export default menuReducer
