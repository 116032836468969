import {Formik} from 'formik'
import * as Yup from 'yup'
import React, {SyntheticEvent, useCallback, useEffect, useState} from 'react'
import './Devices.scss'
import {connect, useDispatch} from 'react-redux'
import {useMutation} from 'urql'
import {graphql} from '@mirror/dataplane'
import {devices as devicesGQL} from '../../graphql/customQueries'
import {Card} from '../../components/Card/Card'
import {Page} from '../../components/Common/Page'
import {SelectInput} from '../../components/Form/SelectInput'
import {TextLikeInput} from '../../components/Form/TextLikeInput'
import {GraphQLError} from '../../elements/GraphQLError'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import {get as _get, isEqual as _isEqual} from 'lodash-es'
import {changeBusy} from '../../store/layout/actions'
import {changePageSetting} from '../../store/settings/actions'
import {RemoteData} from '../../utils/RemoteData'
import {useDataPagination} from '../../utils/UseDataPagination'
import {useDebounce} from '../../utils/UseDebounce'
import {Utility} from '../../utils/Utility'
import {deviceActionsRender, gridLayoutRender, deviceLayoutControls, tableLayoutRender} from './DevicesTemplates'
import g7500 from '../../assets/device-images/poly-g7500.png'
import unknown from '../../assets/device-images/unknown.png'

/* TODO work in progress typing, move to types file
export interface IDeviceActivation {
  when: number
  template: any
  mode: any
  sourceIPGEO: any
}
 */

export interface IDevice {
  family: string
  hardwareRevision: string | null
  id: string
  mac: string
  model: string
  quarantined: boolean
  serial: string
  softwareVersion: string | null
  softwareBuild: string | null
  sourceIP: string | null
  userAgent: string | null
  __typename: 'Device'
  profile: any
  activations: {
    items: any[]
  }
  locked: boolean | null

  // these fields are currently mocked in code in independent variables, but may be added later to official Device schema
  // status?: 'connected' | 'disconnected'
  // icon?: PolyIconOption
  // labelClassName?: string
}

interface IComponentProps {
  changePageSetting: any
  pageSettings: any
  history: any
}

const DevicesBase: React.FC<IComponentProps> = props => {
  const pageSettingsPrefix = 'devices.'
  const dispatch = useDispatch()

  const updateSetting = useCallback(
    (key, value) => {
      // TODO fix typing
      // @ts-ignore
      dispatch(changePageSetting(pageSettingsPrefix + key, value))
    },
    [dispatch]
  )

  const getSetting = (key: string, def = undefined) => {
    return _get(props.pageSettings, pageSettingsPrefix + key, def)
  }

  // track if form has ever been dirty (user entered data),
  // useful for preventing form reset from resetting page layout
  // back to original, which would be jarring
  const [everDirty, setEverDirty] = useState(false)
  // NOTE this is set to 12 because it is divisible by 3 and 4 (common widths for grid),
  // if table length is the same, allows user to keep on same page (e.g. second page looking
  // at results 13-24 of about 36) and still switch between views
  const defaultGridLimit = 12
  const defaultTableLimit = 10
  // deviceVariables.filter needs to be debounced, so it is handled separately
  const [deviceFilter, setDeviceFilter] = useState({} as any)

  // run only once, like a constructor
  useEffect(() => {
    // if changing default display.layout, also change default limit `const [limit, setLimit] = useState(...)`
    updateSetting('display.layout', 'grid')
  }, [updateSetting])

  // debounce changes of deviceFilter to prevent multiple similar queries
  // @help https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
  const debouncedDeviceFilter = useDebounce(deviceFilter, 600, {trailing: true})
  // tracker for deviceFilter updates, set to true when function called, set to false when debounce resolves
  const [pendingFilterUpdate, setPendingFilterUpdate] = useState(false)

  // default, first query should not return many results as it will be mutated after search parameters
  const [deviceVariables, setDeviceVariables] = useState({
    limit: defaultGridLimit,
    filter: deviceFilter,
    cursor: undefined as undefined | string
  })

  // when deviceFilter changes after debounce, update deviceVariables immediately
  useEffect(() => {
    setDeviceVariables(deviceVariables => {
      return {
        ...deviceVariables,
        filter: debouncedDeviceFilter
      }
    })
    setPendingFilterUpdate(false)
  }, [debouncedDeviceFilter])

  // set up pagination on useQuery
  const pagination = useDataPagination(devicesGQL, deviceVariables, setDeviceVariables, 'devices.items', 'devices.next')

  const [deviceModelsQuery, deviceModelOptions] = RemoteData.getDeviceModelOptions()

  dispatch(changeBusy(pagination.query.fetching || deviceModelsQuery.fetching))

  const callTableLayout = (e: React.SyntheticEvent) => {
    updateSetting('display.layout', 'table')
    let newVariables = {
      ...deviceVariables,
      limit: defaultTableLimit
    }
    setDeviceVariables(newVariables)
  }

  const callGridLayout = (e: React.SyntheticEvent) => {
    updateSetting('display.layout', 'grid')
    let newVariables = {
      ...deviceVariables,
      limit: defaultGridLimit
    }
    setDeviceVariables(newVariables)
  }

  const layout = getSetting('display.layout')
  const tableLayout = 'table' === layout
  const gridLayout = 'grid' === layout

  const [, executeUnregisterDevice] = useMutation(graphql.mutations.unregisterDevice)
  const actions = deviceActionsRender(props, Utility.getDeviceLink, executeUnregisterDevice, pagination.executeQuery)

  const searchCard = {
    name: 'Search',
    icon: 'search'
  }

  // TODO would be nice to have product images (pull out device.tsx logic)
  // TODO consider loading placeholder indicators (can pull from OTD .abstract classes) in gridLayout
  let layoutTSX
  if (gridLayout) {
    layoutTSX = gridLayoutRender(pagination.data, Utility.getDeviceLink, actions)
  } else if (tableLayout) {
    layoutTSX = tableLayoutRender(pagination.data, actions)
  }

  // trigger the search when serial or Mac is n+ characters or a device is selected
  const setSearchTerms = (values: any, hasErrors: boolean) => {
    let filter

    if (hasErrors) {
      // don't perform search until validation errors fixed
      filter = {}
    } else {
      filter = Utility.graphQLFilterCombiner([
        {key: 'serial', match: {contains: values.serial}},
        {key: 'mac', match: {contains: values.mac}},
        {key: 'model', match: {eq: values.model}}
      ])
    }

    if (!_isEqual(deviceFilter, filter)) {
      setDeviceFilter(filter)
      setPendingFilterUpdate(true)
    }
  }

  return (
    <Formik
      initialValues={{serial: '', mac: '', model: ''}}
      onSubmit={async values => {
        // alert(JSON.stringify(values, null, 2))
      }}
      validationSchema={Yup.object().shape({
        // TODO implement 2 min characters on serial and mac
        serial: Yup.string().min(2, 'Enter at least 2 characters'),
        mac: Yup.string().min(2, 'Enter at least 2 characters'),
        model: Yup.string()
      })}
    >
      {formikProps => {
        // currently unused: isSubmitting, setFieldValue
        const {values, errors, dirty, handleChange, handleBlur, handleSubmit, handleReset} = formikProps
        const hasErrors = !!Object.keys(errors).length

        // update search terms whether form is dirty (user searching) or empty (cleared)
        setSearchTerms(values, hasErrors)

        let notice, noticeImage
        let showPaginationControls = false
        if (!dirty || Object.keys(errors).length) {
          // shown when there is no search input or there is form data but errors
          notice = 'Enter a search term on left'
          noticeImage = g7500
        } else if (pendingFilterUpdate || pagination.query.fetching) {
          // hide pagination controls until data comes in for first time
          // checking for hasPrevious prevents pagination controls from disappearing during pagination
          if (!everDirty || pagination.hasPrevious) {
            showPaginationControls = true
          }
        } else if (!pagination.data.length) {
          notice = 'No devices found'
          noticeImage = unknown
        } else {
          // data resolved, not waiting for search; is OK to show pagination controls
          showPaginationControls = true
        }

        // set only once when user first enters data into form
        if (dirty && !everDirty) {
          setEverDirty(true)
        }

        if (notice) {
          layoutTSX = (
            <div className="search-placeholder-wrapper">
              <div className="search-placeholder">
                <img src={noticeImage} alt="" />
                <p>{notice}</p>
              </div>
            </div>
          )
        } else if (pagination.query.fetching) {
          // if fetching, don't show old data
          layoutTSX = <div></div>
        }

        const searchCardClasses = everDirty ? 'col-3 auto left-col-sticky animate-from-right' : 'landing mt-3'

        const pageClasses = everDirty ? 'devices-results-page' : 'devices-landing-page'

        const customHandleReset = () => {
          handleReset()
          updateSetting('display.pagesVisible', 1)
        }

        return (
          <Page className={pageClasses} grid={everDirty}>
            {/* left side search column */}
            <Card className={searchCardClasses} contentClasses="p-1" card={searchCard}>
              <form onSubmit={handleSubmit}>
                <TextLikeInput
                  name="serial"
                  type="text"
                  className="wide search-input inline-block"
                  autoFocus
                  placeholder="Search by Serial Number"
                  handleBlur={handleBlur}
                  isValid={!errors.serial}
                  invalidWarning={errors.serial}
                  handleChange={handleChange}
                >
                  <PolyIcon icon="search" size="sm" className="search align-35 ml-25" />
                </TextLikeInput>
                {/* Note handleChange could also be passed something like {Handlers.genericFilter(setFieldValue, 'mac', 'mac')} */}
                <TextLikeInput
                  name="mac"
                  type="text"
                  className="wide search-input inline-block"
                  placeholder="Search by Mac Address"
                  isValid={!errors.mac}
                  invalidWarning={errors.mac}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                >
                  <PolyIcon icon="search" size="sm" className="search align-35 ml-25" />
                </TextLikeInput>
                <SelectInput
                  name="model"
                  title=""
                  options={deviceModelOptions}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                {dirty && (
                  <button className="button button-sm mb-1" onClick={customHandleReset}>
                    <PolyIcon icon="clear" size={14} />
                    Clear Search
                  </button>
                )}
                {/* only show number of results if no errors and not currently searching */}
                {dirty && !pagination.query.fetching && !hasErrors && pagination.resultText}
              </form>
            </Card>
            {!everDirty && (
              <Card to="/device/register" className="mt-3 landing device-register p-1" card={{name: ''}}>
                <PolyIcon customIcon="plus" size="sm" className="plus-1" />
                <PolyIcon customIcon="plus" size="sm" className="plus-2" />
                <div className="new-devices">
                  Register New
                  <br />
                  Device
                </div>
              </Card>
            )}
            {/* right side output column */}
            {everDirty && (
              <div className="col-9">
                <div className="mb-2">
                  {deviceLayoutControls(
                    gridLayout,
                    callGridLayout,
                    tableLayout,
                    callTableLayout,
                    pagination,
                    true,
                    showPaginationControls
                  )}
                  <button
                    className="button right mr-2"
                    onClick={(e: SyntheticEvent) => Utility.redirect(e, props, '/device/register')}
                  >
                    <PolyIcon icon="add_device" className="mr-5" size="xxs" />
                    New Device
                  </button>
                </div>
                <GraphQLError respondTo={pagination.query} />
                {!pagination.query.fetching && (
                  <>
                    {layoutTSX}
                    {showPaginationControls && (
                      <div className="text-center mr-3 pr-3">
                        {deviceLayoutControls(
                          gridLayout,
                          callGridLayout,
                          tableLayout,
                          callTableLayout,
                          pagination,
                          false,
                          showPaginationControls
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </Page>
        )
      }}
    </Formik>
  )
}

// TODO refactor to useSelector as in DevicesLanding.tsx
const mapStateToProps = (state: any) => {
  return {
    // NOTE pulling settings.pages here
    pageSettings: state.settings.pages
  }
}

const Devices = connect(mapStateToProps, {changePageSetting})(DevicesBase)

export {Devices}
