import React from 'react'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import {Utility} from '../../utils/Utility'

const paginationControls = (pagination: any) => {
  if (!pagination.hasPrevious && !pagination.hasNext) {
    return <></>
  }

  const previousButtonClasses = Utility.classNames(['button', pagination.hasPrevious ? 'active' : ''])
  const nextButtonClasses = Utility.classNames(['button', pagination.hasNext ? 'active' : ''])

  return (
    <span className="button-group text-center">
      <button
        className={previousButtonClasses}
        onClick={(e: React.SyntheticEvent) => {
          // preventDefault to prevent a validation or submission event on a parent form, or other negative side effects
          e.preventDefault()
          pagination.previousPage()
        }}
        disabled={!pagination.hasPrevious}
      >
        <PolyIcon icon="keyboard_arrow_left" size={22} />
      </button>
      <button
        className={nextButtonClasses}
        onClick={(e: React.SyntheticEvent) => {
          // preventDefault to prevent a validation or submission event on a parent form, or other negative side effects
          e.preventDefault()
          pagination.nextPage()
        }}
        disabled={!pagination.hasNext}
      >
        <PolyIcon icon="keyboard_arrow_right" size={22} />
      </button>
    </span>
  )
}

export {paginationControls}
