import React from 'react'
import './Tooltip.scss'
import {Utility} from '../../utils/Utility'

interface IComponentProps {
  tooltip: string | React.ReactElement
  children: React.ReactElement | React.ReactElement[]
  // if included, will specify width of tooltip
  className?: string
  width?: number
}

export const Tooltip: React.FC<IComponentProps> = props => {
  const classes = Utility.classNames([props.className, 'tooltip-container'])
  const style = props.width ? {width: props.width + 'px'} : {}

  return (
    <span className={classes}>
      {React.Children.only(props.children)}
      {props.tooltip && (
        <span className="tooltip" style={style}>
          {props.tooltip}
        </span>
      )}
    </span>
  )
}
