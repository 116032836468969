import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, NavLink} from 'react-router-dom'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import {State} from '../../store'
import {get as _get} from 'lodash-es'
import {changeProfileVisible} from '../../store/layout/actions'
import './Header.scss'
import {OptimizelyFeature} from '@optimizely/react-sdk'
import {Utility} from '../../utils/Utility'
import {memoize as _memoize} from 'lodash-es'

interface IComponentProps {
  className?: string
  signedIn?: boolean
}

const Header: React.FC<IComponentProps> = props => {
  const dispatch = useDispatch()
  // also available .search and .hash
  const pathname = useSelector((state: State) => _get(state, 'router.location.pathname'))
  const profileVisible = useSelector((state: State) => _get(state, 'layout.profileVisible'))
  const busy = useSelector((state: State) => _get(state, 'layout.busy'))

  const toggleProfileVisible = () => {
    dispatch(changeProfileVisible(!profileVisible))
  }

  const markClasses = ['polymark', 'd-none', 'd-inline-block-md']
  if (busy) {
    markClasses.push('busy')
  }

  const rightIconClasses = 'mt-1 mr-1'
  // using lodash memoize because useMemo is unavailable in the context of a React Component
  const accountsNavLinkIsActive = _memoize(Utility.isActiveWithVariants(['/account/']))
  const devicesNavLinkIsActive = _memoize(Utility.isActiveWithVariants(['/device/']))

  return (
    <header className={props.className + ' fixed'}>
      <div className="grid">
        <Link to="/" className="col-2 logo ml-5 mt-5">
          <PolyIcon className={markClasses.join(' ')} customIcon="poly_mark" size={40} />
          <PolyIcon className="polyname ml-5 mt-5" customIcon="poly_name" size={40} />
        </Link>
        <nav className="offset-2 col-8 header-nav">
          <ul>
            <li className="d-none d-inline-block-md">
              <NavLink exact to="/" title="Home">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/devices" title="Devices" isActive={devicesNavLinkIsActive}>
                Devices
              </NavLink>
            </li>
            <OptimizelyFeature feature="mono_mirror_manage_accounts_users">
              {isEnabled =>
                isEnabled && (
                  <li>
                    <NavLink to="/accounts" title="Accounts" isActive={accountsNavLinkIsActive}>
                      Accounts
                    </NavLink>
                  </li>
                )
              }
            </OptimizelyFeature>
            <li>
              <NavLink to="/operations" title="Operations">
                Operations
              </NavLink>
            </li>
            {/* TODO https://onecode.polycom-labs.com/mono/core/issues/53 reactivate when available data
            <li>
              <NavLink to="/tenants" title="Tenants">
                <PolyIcon className="mr-5" icon="people_group" size="sm"/>
                Tenants
              </NavLink>
            </li>
            <li>
              <NavLink to="/operations" title="Operations">
                <PolyIcon className="mr-5" icon="statistics" size="sm"/>
                Operations
              </NavLink>
            </li>
            <li>
              <NavLink to="/settings/mirror" title="Mirror Settings">
                Settings
              </NavLink>
            </li>
            */}
          </ul>
        </nav>
        <span className="offset-10 col-2 text-right">
          {/* minimalism: only show profile icon on landing page */}
          {'/' === pathname && <PolyIcon className={rightIconClasses} icon="person" onClick={toggleProfileVisible} />}
          {'/' !== pathname && (
            <Link to="/">
              <PolyIcon customIcon="landing" className={rightIconClasses} />
            </Link>
          )}
        </span>
      </div>
    </header>
  )
}

export {Header}
