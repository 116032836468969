import {ActionType} from 'typesafe-actions'
import * as settingsActions from './actions'

export type SettingsAction = ActionType<typeof settingsActions>

export interface SettingsState {
  changeSetting: any
  changePageSetting: any
  pages: any
}

export enum LayoutActionType {
  changeSetting = 'CHANGE_SETTING',
  changePageSetting = 'CHANGE_PAGE_SETTING'
}
