export const deviceFamilies = `query deviceFamilies {
    __type(name: "DeviceFamily") {
      enumValues {
        name
      }
    }
  }
  `

export const deviceModels = `query deviceModels {
    __type(name: "DeviceModel") {
      enumValues {
        name
      }
    }
  }
  `

export const deviceProviders = `query deviceProviders {
    __type(name: "Provider") {
      enumValues {
        name
      }
    }
  }
  `

/**
 * A variant of Devices in queries.ts returning far fewer fields
 */
export const devices = `query Devices(
  $filter: DeviceFilterInput
  $direction: SortDirection = DESC
  $limit: Int = 100
  $cursor: String
) {
  devices(
    filter: $filter
    direction: $direction
    limit: $limit
    cursor: $cursor
  ) {
    items {
      id
      model
      mac
      serial
    }
    next
  }
}`
