import {OptimizelyFeature} from '@optimizely/react-sdk'
import React, {SyntheticEvent} from 'react'
import {OperationContext, OperationResult} from 'urql'
import {Card} from '../../components/Card/Card'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import {RemoteData} from '../../utils/RemoteData'
import {Utility} from '../../utils/Utility'
import {paginationControls} from '../../components/Common/PaginationControls'

const gridLayoutRender = (devicesFiltered, link: (device) => string, actions: (device) => any) => {
  return (
    <>
      {devicesFiltered.map((device, index) => {
        // TODO temporarily assume all devices are connected
        const status = 'connected'
        const name = status
        const icon = 'connected' === status ? 'container_circle' : 'radio_button_off'
        const labelClassName = `status ${status}`

        // output of each device Card
        return (
          <Card
            to={link(device)}
            key={index}
            index={index}
            className="device"
            animationSpeed={2}
            card={{name, icon, labelClassName}}
            contentClasses="pl-1 pr-1"
          >
            <div>
              <PolyIcon icon="device" className="mr-5" size="xxs" />
              {Utility.deviceReadable(device.model)}
            </div>
            <div>
              <PolyIcon icon="firmware" title="Serial Number" className="mr-5" size="xxs" />
              {device.serial}
            </div>
            <div>
              <PolyIcon icon="ethernet" title="Mac Address" className="mr-5" size="xxs" />
              {device.mac}
            </div>
            {actions(device)}
          </Card>
        )
      })}
    </>
  )
}

const tableLayoutRender = (devicesFiltered, actions: (device) => any) => {
  return (
    <table className="table rounded">
      <thead>
        <tr>
          <th>Model</th>
          <th>
            <PolyIcon icon="firmware" title="Serial Number" className="mr-5" size="xxs" />
            Serial Number
          </th>
          <th>
            <PolyIcon icon="ethernet" title="Mac Address" className="mr-5" size="xxs" />
            Mac Address
          </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {devicesFiltered.map((device, index) => {
          // output of each device Card
          return (
            <tr key={index}>
              <td>{Utility.deviceReadable(device.model)}</td>
              <td>{device.serial}</td>
              <td>{device.mac}</td>
              <td>{actions(device)}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const deviceActionsRender = (
  props: any,
  link: (device) => string,
  executeUnregisterDevice: (
    variables?: object | undefined,
    context?: Partial<OperationContext> | undefined
  ) => Promise<OperationResult<any>>,
  executeDevicesQuery: (opts?: Partial<OperationContext> | undefined) => void
) => {
  const actions = device => (
    <div className="showOnHover actions">
      <button className="action" onClick={(e: SyntheticEvent) => Utility.redirect(e, props, link(device))}>
        <PolyIcon title="Edit" icon="edit" size="xxs" />
      </button>
      <OptimizelyFeature feature="mono_mirror_unregister_devices">
        {isEnabled =>
          isEnabled && (
            <button
              className="action warning"
              onClick={(e: SyntheticEvent) => {
                e.preventDefault()
                RemoteData.handleUnregisterDevice(executeUnregisterDevice, device).then(() => executeDevicesQuery())
              }}
            >
              <PolyIcon title="Unregister" icon="trash" size="xxs" />
            </button>
          )
        }
      </OptimizelyFeature>
    </div>
  )
  return actions
}

const deviceLayoutControls = (
  gridLayout: boolean,
  callGridLayout: (e: React.SyntheticEvent) => void,
  tableLayout: boolean,
  callTableLayout: (e: React.SyntheticEvent) => void,
  pagination: any,
  includeLayoutButtons: boolean,
  includePaginationButtons: boolean
) => {
  return (
    <span className="pagination-controls">
      {includeLayoutButtons && (
        <span className="button-group mr-1">
          <button className={'button ' + (gridLayout ? 'active' : '')} onClick={callGridLayout}>
            <PolyIcon customIcon="th" size="xs" />
          </button>
          <button className={'button ' + (tableLayout ? 'active' : '')} onClick={callTableLayout}>
            <PolyIcon customIcon="table" size="xs" />
          </button>
        </span>
      )}
      {includePaginationButtons && paginationControls(pagination)}
    </span>
  )
}

export {gridLayoutRender, tableLayoutRender, deviceActionsRender, deviceLayoutControls}
