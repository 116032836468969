import React from 'react'
import {Login} from '../Login/Login'
import './Invitation.scss'
import {Redirect} from 'react-router'

interface IComponentProps {
  match: {
    params: {
      result: 'accepted' | 'invalid'
    }
  }
}

const Invitation: React.FC<IComponentProps> = props => {
  const {
    match: {params}
  } = props
  const result = params.result

  const validResultParams = ['accepted', 'invalid']
  if (!validResultParams.includes(result)) {
    // this path will redirect to login if needed
    return <Redirect to="/"></Redirect>
  }

  return (
    <>
      {'accepted' === result && (
        <Login hideButton={false} messageClasses="invitation accepted mt-5 mb-2">
          Your account has been activated
          <br />
          and is ready to access.
        </Login>
      )}
      {'invalid' === result && (
        <Login hideButton={true} messageClasses="invitation rejected mt-5">
          <div>
            Your invitation has expired.
            <br />
            Please contact the system administrator.
          </div>
        </Login>
      )}
    </>
  )
}

export {Invitation}
