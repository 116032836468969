import {Auth} from 'aws-amplify'
import React from 'react'
import './NoAccess.scss'

interface FunctionComponentProps {}

const NoAccess: React.FC<FunctionComponentProps> = props => {
  const logout = () => {
    // If logout successful, will be picked up by auth state listener and redirected
    Auth.signOut().catch(e => {
      console.error('Error signing out', e)
    })
  }

  return (
    <>
      <div className="no-access">
        <p className="warning">
          You have successfully logged in, but you do not have permission to access Poly Mirror. Please reach out to the
          support team on{' '}
          <a href="https://teams.microsoft.com/l/channel/19%3ab66bf1f778404dea9aff0d313b4c3d16%40thread.skype/General?groupId=20b1361c-d4cb-4eb7-b9c7-6a9d2e3ea70e&tenantId=bc7c5f16-c55e-417d-aac0-ff6bbfc27f76">
            Microsoft Teams
          </a>
          .
          <br />
          <br />
          When resolved, please log out and retry.
        </p>
        <button className="button button-sm caution" onClick={logout}>
          <i />
          Log Out
        </button>
      </div>
    </>
  )
}

export {NoAccess}
