import {get as _get, each as _each, result as _result} from 'lodash-es'
import React, {Component} from 'react'
import './SimpleEarth.scss'
import {
  Math,
  Cartesian3,
  Credit,
  SceneMode,
  UrlTemplateImageryProvider,
  Viewer,
  HeadingPitchRoll,
  PinBuilder,
  Transforms,
  Color,
  LabelStyle,
  Cartesian2,
  NearFarScalar,
  VerticalOrigin
} from 'cesium'
import {Utility} from '../../utils/Utility'

interface IComponentProps {
  markers: any[]
  index: number
  zoomToFirstMarker?: boolean
  preventZoom?: boolean
}

class SimpleEarth extends Component<IComponentProps, any> {
  private viewer: any
  private readonly domID: string

  constructor(props) {
    super(props)

    this.domID = `worldContainer-${this.props.index}`
    this.state = {
      renderError: undefined
    }
  }

  initViewer() {
    Utility.setCesiumBaseUrl()

    const viewerOptions = {
      infoBox: false,
      selectionIndicator: false,
      timeline: false,
      fullscreenButton: false,
      baseLayerPicker: false,
      shadows: false,
      animation: false,
      shouldAnimate: false,
      // allows search by area
      geocoder: false,
      navigationHelpButton: false,
      // buttons that makes it simple to go back to initial view
      homeButton: false,
      skyBox: false,
      sceneMode: SceneMode.SCENE2D,
      // prevent renders unless necessary
      // @help https://cesium.com/blog/2018/01/24/cesium-scene-rendering-performance/
      requestRenderMode: true,
      maximumRenderTimeChange: Infinity,
      // no-op placeholder that will be replaced in initLayers
      // this prevents a remote Cesium XHR layer request from being called
      imageryProvider: () => {}
    }
    this.viewer = new Viewer(this.domID, viewerOptions as any)

    // zoom in on area around first marker
    if (this.props.zoomToFirstMarker && this.props.markers && 1 === this.props.markers.length) {
      const firstMarker = this.props.markers[0]
      const center = Cartesian3.fromDegrees(firstMarker.lon, firstMarker.lat)
      // this also locks camera in place
      this.viewer.camera.lookAt(center, new Cartesian3(0.0, 0.0, 9000000.0))
    }

    // this could be parameterized in props if variance needed
    // roughly city level inside a country
    this.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 2000000

    // zoom is enabled by default
    if (this.props.preventZoom) {
      // prevent zooming
      this.viewer.scene.screenSpaceCameraController.enableZoom = false

      const elt = document.getElementById(this.domID) || ({} as any)
      if (elt.addEventListener) {
        elt.addEventListener(Utility.getWheelEvent(elt), e => {
          // scroll page instead of letting Cesium capture/process wheel event
          window.scrollBy(0, e.deltaY)
        })
      }
    }

    // set up by removing default layers and logo credit, background and other settings
    // transparent white, though transparency does not seem to matter
    this.viewer.scene.backgroundColor = {red: 255, green: 255, blue: 255, alpha: 0}
    this.viewer.scene.skyAtmosphere.brightnessShift = -0.8
    this.viewer.scene.skyAtmosphere.hueShift = 0.2

    _result(this, 'viewer.imageryLayers.removeAll')

    // crediting Stamen Toner Lite is optional, so currently hidden in SCSS
  }

  initLayer() {
    const layer = new UrlTemplateImageryProvider({
      url: process.env.PUBLIC_URL + '/tiles/TonerLite/{z}/{x}/{y}.png',
      credit: new Credit('Stamen Toner Lite'),
      minimumLevel: 0,
      maximumLevel: 5
    })

    this.viewer.imageryLayers.addImageryProvider(layer)
  }

  /**
   * Draw primitives.  For additional examples such as adding images see:
   * @example https://cesiumjs.org/Cesium/Build/Apps/Sandcastle/?src=Cesium%20Inspector.html
   */
  drawData() {
    // add Cesium primitive inspector, helpful for debugging
    // this.viewer.extend(viewerCesiumInspectorMixin);

    const height = 1000
    const heading = Math.toRadians(135)
    const pitch = 0
    const roll = 0
    const hpr = new HeadingPitchRoll(heading, pitch, roll)
    const modelUrl = '/models/marker.glb'
    // @help https://cesiumjs.org/Cesium/Build/Apps/Sandcastle/?src=3D%20Models%20Coloring.html
    const pinBuilder = new PinBuilder()

    _each(this.props.markers, datum => {
      const position = Cartesian3.fromDegrees(datum.lon, datum.lat, height)
      const orientation = Transforms.headingPitchRollQuaternion(position, hpr)

      const pointSize = 10
      let entityOptions: any = {
        name: modelUrl,
        position: position,
        label: {
          text: datum.label,
          font: '18px sans-serif',
          // fillColor: Color.SKYBLUE,
          // outlineColor: Color.BLACK,
          outlineWidth: 0,
          showBackground: true,
          backgroundColor: Color.fromCssColorString('rgba(0, 0, 239, 1.0)'),
          style: LabelStyle.FILL_AND_OUTLINE,
          pixelOffset: new Cartesian2(0.0, pointSize + 5),
          pixelOffsetScaleByDistance: new NearFarScalar(1.5e2, 3.0, 1.5e7, 0.5),
          scaleByDistance: new NearFarScalar(1.5e2, 2.0, 1.5e7, 0.5)
        },
        orientation: orientation
      }

      if (datum.pinLabel) {
        entityOptions.billboard = {
          image: pinBuilder.fromText(datum.pinLabel, Color.BLACK, 48).toDataURL(),
          verticalOrigin: VerticalOrigin.BOTTOM
        }
      } else {
        // @help https://sandcastle.cesium.com/?src=Points.html&label=Beginner
        entityOptions.point = {
          color: Color.BLUE,
          pixelSize: pointSize,
          outlineColor: Color.BLUE,
          outlineWidth: 0
        }
      }

      this.viewer.entities.add(entityOptions)
    })
  }

  componentDidMount(): void {
    try {
      this.initViewer()
      this.initLayer()
      this.drawData()
    } catch (e) {
      const originalMessage = 'Raw Message: "' + _get(e, 'message', 'Unknown') + '"'
      let message = `Unable to render visualization. ${originalMessage}`
      if (/webgl/i.test(message)) {
        message = `Unable to render visualization. Your browser may have WebGL disabled or needs to be updated to a modern browser. ${originalMessage}`
      }
      this.setState({renderError: message})
    }
  }

  render() {
    return this.state.renderError ? (
      <p className="caution">{this.state.renderError}</p>
    ) : (
      <div className="simple-earth" id={this.domID} />
    )
  }
}

export {SimpleEarth}
