import {Field} from 'formik'
import React, {ReactElement} from 'react'
import './CheckboxInput.scss'
import {Utility} from '../../utils/Utility'

interface ICheckboxInput {
  name: string
  title: string
  buttonStyling?: boolean
  options: CheckboxInputOptions
}

export type CheckboxInputOptions = {
  label: ReactElement
  value: string
}[]

/**
 * Designed for multi-select checkbox handling.  For boolean or on/off handling,
 * see SwitchInput.tsx.
 *
 * Will create an array of values, for example:
 * types: ['A', 'B]
 *
 * @param props
 * @constructor
 */
const CheckboxInput = (props: ICheckboxInput) => {
  return (
    <div className="form-group checkbox-input">
      <label htmlFor={props.name} className="form-label">
        {props.title}
      </label>
      <div className="checkbox-group">
        {props.options.map((option, index) => {
          const generatedID = `${props.name}-${index}`

          // For this checkbox method, see also @help https://codesandbox.io/s/328038x19q?file=/src/index.js
          return (
            <Field key={index} name={props.name}>
              {({field, form}) => {
                const classes = Utility.classNames(['form-option', props.buttonStyling ? 'button button-sm' : ''])

                return (
                  <div className={classes}>
                    <div className="checkbox-wrapper">
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        id={generatedID}
                        name={props.name}
                        value={option.value}
                        checked={field.value.includes(option.value)}
                        onChange={() => {
                          // input value already included, remove it
                          if (field.value.includes(option.value)) {
                            const nextValue = field.value.filter(value => value !== option.value)
                            form.setFieldValue(props.name, nextValue)

                            return
                          }

                          // input value should be added
                          const nextValue = field.value.concat(option.value)
                          form.setFieldValue(props.name, nextValue)
                        }}
                      />
                    </div>
                    <label htmlFor={generatedID}>{option.label}</label>
                  </div>
                )
              }}
            </Field>
          )
        })}
      </div>
    </div>
  )
}

export default CheckboxInput
