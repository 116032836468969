import React from 'react'
import {debounce as _debounce, isFunction as _isFunction, noop as _noop} from 'lodash-es'
import {Utility} from '../../utils/Utility'
import {FormUtility} from './FormUtility'
import {Field} from 'formik'

interface ITextLikeInputProps {
  name: string
  className: string
  type?:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week'
  title?: string
  // NOTE value is not included here, since it is assumed Formik will set an initial value
  // then update the value from there on out
  cols?: number
  rows?: number
  maxLength?: number
  pattern?: string
  placeholder?: string
  isValid?: boolean
  invalidWarning?: string
  // TODO improve typing
  handleChange: any
  // blur required because otherwise touched events are not tracked properly
  handleBlur: any
  debounce?: number
  autoFocus?: true
  disabled?: boolean
  readOnly?: boolean
  // TODO improve typing
  children?: any
}

const TextLikeInput = (props: ITextLikeInputProps) => {
  let classes = Utility.classNames(['form-group', props.className])

  const [isValid, invalidElement] = FormUtility.getValidationFromProps(props)
  classes += isValid ? ' valid' : ' invalid'

  const isTextArea = props.cols || props.rows
  const type = props.type || 'text'

  // set change handler, and optionally debounce interval in milliseconds
  let onChange = props.handleChange || _noop
  if (props.debounce && _isFunction(props.handleChange)) {
    const debounceHandleChange = _debounce(props.handleChange, props.debounce)
    onChange = (e: React.SyntheticEvent) => {
      // @help https://reactjs.org/docs/events.html#event-pooling
      // without e.persist, event is immediately nullified and not accessible in the future
      e.persist()

      debounceHandleChange(e)
    }
  }

  return (
    <div className={classes}>
      {props.title && (
        <label htmlFor={props.name} className="form-label">
          {props.title}
        </label>
      )}
      <Field name={props.name}>
        {({field}) => {
          return (
            <>
              {isTextArea && (
                <textarea
                  className="form-input form-control"
                  id={props.name}
                  name={props.name}
                  value={field.value}
                  onBlur={props.handleBlur}
                  onChange={onChange}
                  maxLength={props.maxLength}
                  placeholder={props.placeholder}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                  autoFocus={props.autoFocus}
                />
              )}
              {!isTextArea && (
                <input
                  className="form-input form-control"
                  id={props.name}
                  name={props.name}
                  type={type}
                  value={field.value}
                  onBlur={props.handleBlur}
                  onChange={onChange}
                  maxLength={props.maxLength}
                  pattern={props.pattern}
                  placeholder={props.placeholder}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                  autoFocus={props.autoFocus}
                />
              )}
            </>
          )
        }}
      </Field>
      {invalidElement}
      {props.children}
    </div>
  )
}

export {TextLikeInput}
