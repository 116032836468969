import {graphql} from '@mirror/dataplane'
import {formatRelative, fromUnixTime, format} from 'date-fns/esm'
import {filter as _filter, get as _get, has as _has, map as _map, mapValues as _mapValues, size as _size, startCase as _startCase, toLower as _toLower, upperFirst as _upperFirst} from 'lodash-es'
import React, {Component, SyntheticEvent} from 'react'

export interface IFilter {
  key: string
  match: any
}

class Utility extends Component {
  /**
   * Adds thousands separator to whole numbers.
   *
   * Note if a React element or null is passed as number, will return unmodified.
   *
   * @param {number|null|any} number
   * @param {string} thousands - thousands separator
   */
  static formatWholeNumber = (number, thousands = ',') => {
    if (null === number || React.isValidElement(number)) {
      return number
    }

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousands)
  }

  static envVariable = (key: string, attemptBooleanConversion = true, defaultValue: any = undefined) => {
    // WARNING likely due to some React+webpack "magic", process.env has to be accessed
    // as such (properly initialized object), not as process then check for key env (empty object)

    // put simply:
    // console.log(process) // env is an empty object
    // console.log(process.env) // env is properly initialized

    if (!_has(process.env, key)) {
      return defaultValue
    }

    let value = process.env[key]

    // convert a value that looks like boolean
    return attemptBooleanConversion ? Utility.runBooleanConversion(value) : value
  }

  static runBooleanConversion = (value: string | undefined) => {
    if ('true' === value || 'false' === value) {
      return 'true' === value
    }

    return value
  }

  /**
   * Convenient way to parse all environmental variables, e.g. to pick up value REACT_APP_HIDE_TRIO=true,
   * `const {REACT_APP_HIDE_TRIO} = Utility.envVariables()` will return the boolean value `true`
   *
   * @param attemptBooleanConversion
   */
  static envVariables = (attemptBooleanConversion = true): any => {
    return _mapValues(process.env, val => {
      return attemptBooleanConversion ? Utility.runBooleanConversion(val) : val
    })
  }

  /**
   * Given a provider label such as "video_conference", attempt to create
   * a nice string format for it such as "Video Conference".
   *
   * @param provider
   */
  static providerReadable = (provider: string): string => {
    return _startCase(provider)
  }

  static deviceReadable = (device: string): string => {
    let label = device

    // readability improvements
    // TRIO8500 => Trio 8500
    label = label.replace(/^TRIO/, 'Trio ')
    // VVX101 => VVX 101
    label = label.replace(/^VVX/, 'VVX ')
    // X30 => Studio X30
    label = label.replace(/^X/, 'Studio X')

    return label
  }

  static profileReadable = (profile?: graphql.Provider): string => {
    if (!profile) {
      return ''
    }

    let profileReadable: string = profile

    switch (profile) {
      // handle special cases
      case 'DEVICE':
        profileReadable = 'Device Mode'
        break
      case 'MICROSOFT':
        profileReadable = 'Microsoft Teams'
        break
      case 'EIGHTxEIGHT':
        profileReadable = '8x8'
        break
      case 'BLUEJEANS':
        profileReadable = 'BlueJeans'
        break
      case 'STARLEAF':
        profileReadable = 'StarLeaf'
        break
      case 'LOGMEIN':
        profileReadable = 'GoToMeeting'
        break
      case 'RINGCENTRAL':
        profileReadable = 'RingCentral'
        break
      case 'TENCENT':
        profileReadable = 'Tencent'
        break
      case 'GOOGLE':
        profileReadable = 'Google Meet'
        break
      default:
        profileReadable = Utility.defaultReadableConvert(profileReadable)
        break
    }

    return profileReadable
  }

  static defaultReadableConvert = (value: string) => {
    // default case will be transformed as follows:
    // MICROSOFT_TEAMS => MICROSOFT TEAMS
    value = value.replace('_', ' ')

    // MICROSOFT TEAMS => Microsoft Teams
    return _startCase(_toLower(value))
  }

  static redirect = (e: SyntheticEvent | undefined, props: any, path: string, delayMS = 0) => {
    if (e) {
      e.preventDefault()
    }

    if (!delayMS) {
      props.history.push(path)
    } else {
      setTimeout(() => {
        props.history.push(path)
      }, delayMS)
    }
  }

  /**
   * Utility to format a relative time based on a unix timestamp.
   *
   * @param unixTime
   * @param timeDefault
   * @param upperFirst - if trust, will transform "today at 2:55 PM" to "Today at 2:55 PM"
   */
  static relativeFromUnixTime = (unixTime: number | null, timeDefault = 'Unknown', upperFirst = true): string => {
    if (!unixTime) {
      return timeDefault
    }

    let formatRelativeText = formatRelative(fromUnixTime(unixTime), new Date())

    // remove leading 0, e.g. "06/08/2020".
    if ('0' === formatRelativeText[0]) {
      formatRelativeText = formatRelativeText.slice(1)
    }

    return upperFirst ? _upperFirst(formatRelativeText) : formatRelativeText
  }

  /**
   * Utility to display a formatted time based on a unix timestamp.
   *
   * @param unixTime
   * @param timeDefault
   * @param dateFormat - format per https://date-fns.org/v2.5.1/docs/format
   */
  static formatFromUnixTime = (unixTime: number | null, timeDefault = 'Unknown', dateFormat = 'MMM d, yyyy h:mm:ss a'): string => {
    if (!unixTime) {
      return timeDefault
    }

    return format(fromUnixTime(unixTime), dateFormat)
  }

  /**
   * Utility to get parameter from the current URL (default) or the provided URL.
   *
   * @param name - e.g. 'error'
   * @param url - e.g. 'https://example.com?error=RM83'
   */
  static getParameterByName = (name: string, url?: string): string => {
    if (!url) url = window.location.href
    name = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(url)
    if (!results || !results[2]) return ''

    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  /**
   * Helper functino to set Cesium Base URL.  This is where various Cesium assets are located.
   * This output is generated at build/cesium, or in React dev mode accessible at
   * localhost:3000/cesium/Cesium.js (example file location).
   * If using the Cesium CDN, this is unnecessary.
   */
  static setCesiumBaseUrl() {
    // simple passthrough of current href to CESIUM_BASE_URL
    ;(window as any).CESIUM_BASE_URL = window.location.origin + '/cesium/'
  }

  /**
   * Based on Cesium ScreenSpaceEventHandler, for parity.
   */
  static getWheelEvent(element) {
    if ('onwheel' in element) {
      // spec event type
      return 'wheel'
    } else if ((document as any).onmousewheel !== undefined) {
      // legacy event type
      return 'mousewheel'
    } else {
      // older Firefox
      return 'DOMMouseScroll'
    }
  }

  static graphQLFilterCombiner(filters: IFilter[]) {
    filters = filters || []
    const filtersPruned = _filter(filters, filter => {
      // for now, a safe assumption that there is only one comparator such as contains or beginsWith
      const comparator = Object.keys(filter.match)[0]
      const val = filter.match[comparator]

      // exclude any filters with an empty value such as { contains: '' }
      return !!val
    })

    let graphQLFilters = {}

    switch (_size(filtersPruned)) {
      case 1:
        graphQLFilters = {[filtersPruned[0].key]: filtersPruned[0].match}
        break
      case 0:
        break
      default:
        // multiple filters, combine with and
        const combinedFilters = _map(filtersPruned, (filter: IFilter) => {
          return {[filter.key]: filter.match}
        })
        graphQLFilters = {
          and: combinedFilters
        }
    }

    return graphQLFilters
  }

  /**
   * @param query - an urql useQuery instance that has a .data object
   * @param path - e.g. 'data.devices.next'
   */
  static getNext(query: any, path: string): string | undefined {
    const next = _get(query, path) as string

    return next || undefined
  }

  static hasNext(query: any, path: string): boolean {
    return !!this.getNext(query, path)
  }

  static getEnv = () => {
    return Utility.envVariable('REACT_APP_ENV', false, 'development')
  }

  /**
   * React Router-friendly scroll to a specific anchor on the page based on hash.
   */
  static hashLinkFocus = () => {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    // TODO nice to have a trigger for React DOM mounted, rather than arbitrary timeout
    //   as a static method called from a Functional Component, this seems a challenge
    setTimeout(() => {
      const element = Utility.hashLink()
      if (element) {
        if (element.classList.contains('tab-link')) {
          // no action needed, will be handled by Tabs component
          return
        }

        element.scrollIntoView({behavior: 'smooth'})
      }
    }, 1300)
  }

  static getURLHash = (): string | undefined => {
    const {hash} = window.location

    if (hash !== '') {
      return hash.replace('#', '')
    }
  }

  /**
   * React Router-friendly scroll to a specific anchor on the page based on hash.
   */
  static hashLink = (): HTMLElement | void => {
    const hash = Utility.getURLHash()
    if (hash) {
      const element = document.getElementById(hash)

      if (element) {
        return element
      }
    }
  }

  static classNames = (classNames: (string | undefined)[]) => {
    return classNames.filter(c => !!c).join(' ')
  }

  static selectContentsByID = elementID => {
    const el = document.getElementById(elementID)
    if (el) {
      document.getSelection()?.selectAllChildren(el as Node)
    }
  }

  static getDeviceLink = (device: any) => {
    return `/device/${device.id}`
  }

  /**
   * Helper function when NavLink should be considered active when an exact match
   * and a loose match is found.
   *
   * Note this function can be wrapped around useMemo (if called from a React.FC) or
   * via lodash _.memoize if called from a context like a React Component.
   *
   * @param activeWhenStartsWith - array of strings such as '/account/' that will be matched against location.pathname
   */
  static isActiveWithVariants = (activeWhenStartsWith: string[]) => (match, location) => {
    if (match) {
      return true
    }

    // return true if any paths in
    return activeWhenStartsWith.some(variant => {
      return location.pathname.startsWith(variant)
    })
  }

  static toUnixStamp = (date: Date) => {
    if (!date || !(date instanceof Date)) {
      console.error('Could not get unix timestamp from date', date)
      return date
    }

    return date.getTime() / 1000
  }
}

export {Utility}
