import React, {ReactElement, SyntheticEvent} from 'react'
import './Device.scss'
import {useDispatch} from 'react-redux'
import {useMutation} from 'urql'
import {graphql} from '@mirror/dataplane'
import {Card} from '../../components/Card/Card'
import {Page} from '../../components/Common/Page'
import {SelectInput} from '../../components/Form/SelectInput'
import {Abstract} from '../../elements/Abstract'
import {GraphQLError} from '../../elements/GraphQLError'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import {get as _get, has as _has, size as _size, startCase as _startCase, toLower as _toLower, toUpper as _toUpper} from 'lodash-es'
import {useQuery} from '../../graphql/graphqlClient'
import {changeBusy} from '../../store/layout/actions'
import {RemoteData} from '../../utils/RemoteData'
import {Utility} from '../../utils/Utility'
import {DeviceActivation} from './DeviceActivation'
import {IDevice} from './Devices'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {withOptimizely} from '@optimizely/react-sdk'

interface IComponentProps {
  match: {
    params: {
      id: string
    }
  }
  optimizely: any
}

const Device: React.FC<IComponentProps> = props => {
  // array of provisioningOptions' selected values
  const dispatch = useDispatch()

  const {
    match: {
      params: {id}
    },
    optimizely
  } = props
  const [deviceQuery, executeDeviceQuery] = useQuery(graphql.queries.device, {id})
  const [deviceBaseProfilesQuery, deviceBaseProfileOptions] = RemoteData.getDeviceProfileOptions('Select a Profile to Apply')

  dispatch(changeBusy(deviceQuery.fetching || deviceBaseProfilesQuery.fetching))

  // NOTE a variable or function that returns a string cannot be used in require, gives error:
  // "Cannot find module '../../assets/device-images/unknown.png'"
  const deviceImages = {
    UNKNOWN: require('../../assets/device-images/unknown.png'),
    G7500: require('../../assets/device-images/poly-g7500.png'),
    X30: require('../../assets/device-images/poly-x30.jpg'),
    X32: require('../../assets/device-images/unknown.png'),
    X50: require('../../assets/device-images/poly-x50.jpg'),
    OBAN50: require('../../assets/device-images/unknown.png'),
    X70: require('../../assets/device-images/poly-x70.png'),
    X72: require('../../assets/device-images/unknown.png'),
    G62: require('../../assets/device-images/unknown.png'),
    TRIO8500: require('../../assets/device-images/poly-trio-8500.png'),
    TRIO8800: require('../../assets/device-images/poly-trio-8800.png'),
    VVX101: require('../../assets/device-images/poly-vvx-101.jpg'),
    VVX150: require('../../assets/device-images/poly-vvx-150.png'),
    VVX201: require('../../assets/device-images/poly-vvx-201.jpg'),
    VVX250: require('../../assets/device-images/poly-vvx-250.png'),
    VVX300: require('../../assets/device-images/poly-vvx-300.jpg'),
    VVX350: require('../../assets/device-images/poly-vvx-350.png'),
    VVX400: require('../../assets/device-images/poly-vvx-400.jpg'),
    VVX450: require('../../assets/device-images/poly-vvx-450.png'),
    VVX500: require('../../assets/device-images/poly-vvx-500.jpg'),
    VVX600: require('../../assets/device-images/poly-vvx-600.jpg')
  }
  // TODO for now, always assume connected
  const status = 'connected'
  const statusClass = `status ${status}`
  const statusIcon = 'connected' === status ? 'container_circle' : 'radio_button_off'

  const statusJSX = (
    <span className={statusClass}>
      <PolyIcon className="mr-25" icon={statusIcon} size={16} />
      <span>{status}</span>
    </span>
  )

  let overview: {label: string; output: string | ReactElement | null}[] = []
  let model = 'Unknown'
  let locked
  const device = _get(deviceQuery, 'data.device') as IDevice
  if (device) {
    model = device.model ? device.model : model
    locked = _get(device, 'profile.locked')

    overview = [
      {label: 'Status', output: statusJSX},
      {label: 'Type', output: _startCase(_toLower(device.family))},
      {label: 'Model', output: model},
      {label: 'Serial Number', output: device.serial},
      {label: 'Mac Address', output: device.mac},
      {label: 'IP Address', output: device.sourceIP},
      {label: 'Software Version', output: device.softwareVersion},
      {label: 'Software Build', output: device.softwareBuild},
      {label: 'User Agent', output: device.userAgent}
    ]
  }

  /*
  // likely useful
    // configuration options
    const mode = <RadioInput name="configuration.mode" id="mode" title="Mode" options={modeOptions}
                             initialValue={_get(props, 'TODO')}/>
    // handleChange={this.handleChange.bind(this)}
    const launcher = <RadioInput name="configuration.launcher" id="launcher" title="Launcher"
                                 options={launcherOptions} initialValue={_get(props, 'TODO')}/>
    const enabledApps = <RadioInput name="configuration.enabledApps" id="enabledApps" title="Enabled Apps"
                                    options={enabledAppsOptions} initialValue={_get(props, 'TODO')}/>
    const provisioning = <RadioInput name="configuration.provisioning" id="provisioning" title="Provisioning"
                                     options={provisioningOptions} initialValue={_get(props, 'TODO')}/>
    const configuration = [mode, launcher, enabledApps, provisioning]
    */

  // default images to unknown if not set
  const deviceImageModel = _has(deviceImages, _toUpper(model)) ? _toUpper(model) : 'UNKNOWN'
  const deviceImage = _get(deviceImages, deviceImageModel)

  const [, executeUnregisterDevice] = useMutation(graphql.mutations.unregisterDevice)
  const [, executeSetDeviceProfile] = useMutation(graphql.mutations.setDeviceProfile)
  const [, executeUnsetDeviceProfile] = useMutation(graphql.mutations.unsetDeviceProfile)
  const [, executeQuarantineDevice] = useMutation(graphql.mutations.quarantineDevice)
  const [, executeUnquarantineDevice] = useMutation(graphql.mutations.unquarantineDevice)
  const [, executeLockDevice] = useMutation(graphql.mutations.lockDeviceProfile)
  const [, executeUnlockDevice] = useMutation(graphql.mutations.unlockDeviceProfile)

  const isUnregisterFieldEnabled = optimizely.isFeatureEnabled('mono_mirror_unregister_devices')
  const isLockFeatureEnabled = optimizely.isFeatureEnabled('mono_mirror_lock_device')

  let deviceActivations
  if (_size(_get(device, 'activations.items')) > 0) {
    deviceActivations = device.activations.items.map((activation, i) => {
      return <DeviceActivation key={i} index={i} activation={activation} />
    })
  } else {
    deviceActivations = <p>There are no device activations.</p>
  }

  return (
    <Page className="text-center" title="Device Details">
      {/* left side search column */}
      {/* TODO abstract loading */}
      <Card className="device-left-col col-3 auto left-col-sticky" index={1} contentClasses="p-1">
        {deviceQuery.fetching && <Abstract layout={['sm-square-center', 'spacer', 'xs', 'sm', 'xs', 'xs', 'sm', 'xs', 'sm', 'md', 'xs', 'sm', 'sm', 'md', 'xs', 'md', 'xs', 'sm']} />}
        {!deviceQuery.fetching && (
          <>
            <div className="left-col-image">
              <img src={deviceImage} alt={model} />
            </div>
            {overview.map((row, index) => {
              return (
                <div key={index}>
                  {row.label && <label>{row.label}</label>}
                  {row.output && <span>{row.output}</span>}
                  {!row.output && <span>Unknown</span>}
                </div>
              )
            })}
          </>
        )}
      </Card>
      {/* right side output column */}
      <div className="col-9">
        {/* CONFIGURATION */}
        <Card className={'device-section label-layout ' + (locked ? 'red-border' : '')} index={2} headerText="Activation Profile" headerIcon="zt_profiles">
          <GraphQLError respondTo={deviceQuery} />
          {device && (
            <>
              {null === device.profile && (
                <>
                  <p>No profile applied. User will be prompted for configuration on initial boot.</p>
                  {deviceBaseProfilesQuery.fetching && <p>Loading profiles...</p>}
                  {!deviceBaseProfilesQuery.fetching && deviceBaseProfileOptions && (
                    <Formik
                      initialValues={{deviceBaseProfile: ''}}
                      onSubmit={async values => {
                        // alert(JSON.stringify(values, null, 2))
                      }}
                      validationSchema={Yup.object().shape({
                        // TODO implement 2 min characters on serial and mac
                        deviceBaseProfile: Yup.string().min(2, 'Select a Profile')
                      })}
                    >
                      {formikProps => {
                        // currently unused: isSubmitting, setFieldValue, errors
                        // no error handling necessary since only one dropdown
                        const {values, handleBlur, handleChange} = formikProps

                        return (
                          <>
                            <div className="grid">
                              <SelectInput className="col-12 col-md-6" name="deviceBaseProfile" title="" options={deviceBaseProfileOptions} handleBlur={handleBlur} handleChange={handleChange} />
                            </div>
                            {values.deviceBaseProfile && (
                              <button
                                className="button button-sm info"
                                onClick={() =>
                                  RemoteData.handleSetDeviceProfile(executeSetDeviceProfile, device, values.deviceBaseProfile)
                                    // refresh device data
                                    .then(() => executeDeviceQuery())
                                }
                              >
                                <PolyIcon icon="profile_deploy" size={16} />
                                Apply {Utility.profileReadable(values.deviceBaseProfile as graphql.Provider)} Profile
                              </button>
                            )}
                          </>
                        )
                      }}
                    </Formik>
                  )}
                </>
              )}
              {null !== device.profile && (
                <>
                  <div>
                    <label>Profile</label>
                    <span>
                      <span className="mr-5">Poly OS {Utility.profileReadable(device.profile.provider)}</span>
                      {locked && (
                        <span className="warning-highlight">
                          <PolyIcon className="mr-25" icon="lock" size={14} />
                          Locked
                        </span>
                      )}
                      {!locked && (
                        <span className="success-highlight">
                          <PolyIcon className="mr-25" icon="unlock" size={14} />
                          Not Locked
                        </span>
                      )}
                    </span>
                  </div>
                  <div>
                    <label>Applied</label>
                    <span>{Utility.relativeFromUnixTime(device.profile.createdAt)}</span>
                  </div>
                  <div>
                    <label>Applied By</label>
                    <span>{'Unknown'}</span>
                  </div>
                  <div className="mt-5">
                    <button
                      className="button button-sm caution mr-5"
                      onClick={() =>
                        RemoteData.handleUnsetDeviceProfile(executeUnsetDeviceProfile, device)
                          // refresh device data
                          .then(() => executeDeviceQuery())
                      }
                    >
                      Remove Profile
                    </button>
                    {isLockFeatureEnabled && device && (
                      <>
                        {locked && (
                          <button
                            className="button button-sm caution"
                            onClick={(e: SyntheticEvent) => {
                              RemoteData.handleLockAndUnlockDevice(executeUnlockDevice, device, false)
                                // refresh device data
                                .then(() => executeDeviceQuery())
                            }}
                          >
                            Remove Lock
                          </button>
                        )}
                        {!locked && (
                          <button
                            className="button button-sm caution"
                            onClick={(e: SyntheticEvent) => {
                              RemoteData.handleLockAndUnlockDevice(executeLockDevice, device, true)
                                // refresh device data
                                .then(() => executeDeviceQuery())
                            }}
                          >
                            Lock Device
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </Card>
        {/* ACTIVATIONS */}
        <Card className="device-section label-layout" index={3} headerText="Activations" headerIcon="zt_profiles">
          {deviceActivations}
        </Card>
        {/* SECURITY */}
        <Card className={'device-section label-layout ' + (device && device.quarantined ? 'red-border' : '')} index={4} headerText="Security" headerIcon="security">
          <div className="mb-5">Device is {device && device.quarantined ? '' : <b>not</b>} quarantined.</div>
          {device && !device.quarantined && (
            <button
              className="button button-sm caution"
              onClick={() =>
                RemoteData.handleQuarantineDevice(executeQuarantineDevice, device)
                  // refresh device data
                  .then(() => executeDeviceQuery())
              }
            >
              Quarantine
            </button>
          )}
          {device && device.quarantined && (
            <button
              className="button button-sm warning"
              onClick={() =>
                RemoteData.handleUnquarantineDevice(executeUnquarantineDevice, device)
                  // refresh device data
                  .then(() => executeDeviceQuery())
              }
            >
              Remove Quarantine
            </button>
          )}
        </Card>
        {isUnregisterFieldEnabled && (
          // if optimizely action feature flags are on, show Actions card
          <Card className="device-section label-layout" index={5} headerText="Other Actions" headerIcon="configure">
            {isUnregisterFieldEnabled && (
              <div>
                <div className="mb-5">This device can be unregistered.</div>
                <button
                  className="button button-sm warning"
                  onClick={(e: SyntheticEvent) => {
                    RemoteData.handleUnregisterDevice(executeUnregisterDevice, device).then(() => {
                      Utility.redirect(e, props, '/devices', 5000)
                    })
                  }}
                >
                  <PolyIcon icon="trash" size={16} />
                  Unregister
                </button>
              </div>
            )}
          </Card>
        )}
      </div>
    </Page>
  )
}

const DeviceWithOptimizely = withOptimizely(Device as any)

export {DeviceWithOptimizely as Device}
