import {Utility} from './utils/Utility'

export const amplify = {
  aws_project_region: Utility.envVariable('REACT_APP_AWS_PROJECT_REGION', false, 'us-east-1'),
  aws_appsync_graphqlEndpoint: Utility.envVariable('REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT'),
  aws_appsync_region: Utility.envVariable('REACT_APP_AWS_APPSYNC_REGION', false, 'us-east-1'),
  aws_appsync_authenticationType: Utility.envVariable(
    'REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE',
    false,
    'AMAZON_COGNITO_USER_POOLS'
  ),
  aws_appsync_apiKey: Utility.envVariable('REACT_APP_AWS_APPSYNC_APIKEY'),
  aws_cognito_region: Utility.envVariable('REACT_APP_AWS_COGNITO_REGION', false, 'us-east-1'),
  aws_user_pools_id: Utility.envVariable('REACT_APP_AWS_USER_POOLS_ID'),
  aws_user_pools_web_client_id: Utility.envVariable('REACT_APP_MIRROR_AWS_USER_POOLS_WEB_CLIENT_ID'),
  oauth: {
    domain: Utility.envVariable('REACT_APP_AWS_OAUTH_DOMAIN'),
    scope: Utility.envVariable('REACT_APP_MIRROR_AWS_OAUTH_SCOPE', false, '').split(','),
    redirectSignIn: Utility.envVariable('REACT_APP_MIRROR_AWS_OAUTH_REDIRECTSIGNIN', false, 'http://localhost:3000/'),
    redirectSignOut: Utility.envVariable('REACT_APP_MIRROR_AWS_OAUTH_REDIRECTSIGNOUT', false, 'http://localhost:3000/'),
    responseType: Utility.envVariable('REACT_APP_AWS_OAUTH_RESPONSETYPE', false, 'code')
  },
  federationTarget: Utility.envVariable('REACT_APP_AWS_FEDERATIONTARGET', false, 'COGNITO_USER_POOLS'),
  aws_user_files_s3_bucket: Utility.envVariable('REACT_APP_AWS_USER_FILES_S3_BUCKET'),
  aws_user_files_s3_bucket_region: Utility.envVariable('REACT_APP_AWS_USER_FILES_S3_BUCKET_REGION'),
  aws_mobile_analytics_app_id: Utility.envVariable('REACT_APP_AWS_MOBILE_ANALYTICS_APP_ID'),
  aws_mobile_analytics_app_region: Utility.envVariable('REACT_APP_AWS_MOBILE_ANALYTICS_APP_REGION', false, 'us-east-1'),
  aws_content_delivery_bucket: Utility.envVariable('REACT_APP_AWS_CONTENT_DELIVERY_BUCKET'),
  aws_content_delivery_bucket_region: Utility.envVariable(
    'REACT_APP_AWS_CONTENT_DELIVERY_BUCKET_REGION',
    false,
    'us-east-1'
  ),
  aws_content_delivery_url: Utility.envVariable('REACT_APP_AWS_CONTENT_DELIVERY_URL')
}
