import './AccountMembers.scss'
import {cloneDeep as _cloneDeep, get as _get, toLower as _toLower} from 'lodash-es'
import React, {SyntheticEvent, useEffect, useState} from 'react'
import Select from 'react-select'
import {EntityManager} from '../../components/EntityManager/EntityManager'
import {AccountQuery, AddMemberInput} from '@mirror/dataplane/build/graphql'
import {FormUtility} from '../../components/Form/FormUtility'
import {SelectInput} from '../../components/Form/SelectInput'
import {TextLikeInput} from '../../components/Form/TextLikeInput'
import {useMutation} from 'urql'
import {graphql} from '@mirror/dataplane'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {RemoteData} from '../../utils/RemoteData'
import {Utility} from '../../utils/Utility'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import DateTimePickerInput from '../../components/Form/DateTimePickerInput'
import {addDays, startOfDay} from 'date-fns/esm'
import {Tooltip} from '../../components/Tooltip/Tooltip'
import {useDataPagination} from '../../utils/UseDataPagination'

interface IComponentProps {
  // account identifier, e.g. 01E8QQJ2...
  account: string
  listQuery: any
  executeListQuery: any
  listQueryAccessor: string
  executeAccountQuery: any
  isOperations?: true
  pagination?: any
}

const selectStyles = {
  control: provided => ({...provided, minWidth: 240, margin: 8}),
  menu: () => ({boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)'})
}

const Menu = props => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)'
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2
      }}
      {...props}
    />
  )
}
const Blanket = props => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1
    }}
    {...props}
  />
)
const Dropdown = ({children, isOpen, target, onClose}) => (
  <div style={{position: 'relative'}}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
)
const DropdownIndicator = () => <PolyIcon title="Move" icon="search" size="xxs" />

const AccountMembers: React.FC<IComponentProps> = props => {
  const nameSingular = 'Member'
  const namePlural = 'Members'
  const getCurrentStamp = () => Math.ceil(new Date().getTime() / 1000)
  // interval to update debouncedStamp
  const stampUpdateMS = 1000

  const [, executeAddMemberMutation] = useMutation(graphql.mutations.addMember)
  const [, executeMoveMember] = useMutation(graphql.mutations.moveMember)
  const [, executeRemoveMember] = useMutation(graphql.mutations.removeMember)
  const [, executeResendInvite] = useMutation(graphql.mutations.resendInvite)
  // update stamp every stampUpdateMS seconds to keep time-related elements on page updated
  const [debouncedStamp, setDebouncedStamp] = useState(getCurrentStamp())

  const [isOpen, setOpen] = useState(false)
  const toggle = () => setOpen(on => !on)
  const onSelectChange = value => {
    console.log('value', value)
    toggle()
  }

  const [accountsVariables, setAccountsVariables] = useState({
    limit: 500,
    cursor: undefined as undefined | string
  })
  const pagination = useDataPagination('query Accounts($limit: Int = 100, $cursor: String) {accounts(limit: $limit, cursor: $cursor) {items {id\n name}\n next}}', accountsVariables, setAccountsVariables, 'accounts.items', 'accounts.next')
  const {query: accountsQuery, executeQuery: executeAccountsQuery} = pagination
  const options = accountsQuery?.data?.accounts?.items?.map(account => ({value: account.id, label: account.name})).filter(option => option.value !== props.account)

  const roleOptions = FormUtility.getRoleOptions(props.isOperations)
  // NOTE conversion to int (leading `+`) here is critical
  const REACT_APP_MIRROR_INVITE_COOLDOWN_SECONDS = +Utility.envVariable('REACT_APP_MIRROR_INVITE_COOLDOWN_SECONDS', false, 600)

  useEffect(() => {
    const interval = setInterval(() => {
      setDebouncedStamp(getCurrentStamp())
    }, stampUpdateMS)
    return () => clearInterval(interval)
  }, [])

  // adding new inline fields to form? check if logic from a past version of
  // MirrorSettings.tsx will work (e.g. expire time picker)
  // NOTE changing label will also change the className, e.g. 'col-status'
  const listFormatter = {
    fields: [
      {
        label: 'Status',
        hideColumnLabel: true,
        formatter: (member: AccountQuery['account']['members']['items'][0]) => {
          const classes = Utility.classNames(['status-indicator', _toLower(member.status ?? '')])

          return (
            <Tooltip tooltip={FormUtility.formatStatus(member.status)}>
              <span className={classes} />
            </Tooltip>
          )
        }
      },
      {
        label: 'Email',
        inlineAdd: ({errors, handleBlur, handleChange}) => {
          return (
            <span>
              <TextLikeInput name="email" placeholder="admin@example.com" className="inline-add" title="" autoFocus isValid={!errors.email} invalidWarning={errors.email} handleBlur={handleBlur} handleChange={handleChange} />
            </span>
          )
        },
        formatter: (member: AccountQuery['account']['members']['items'][0]) => member.email
      },
      {
        label: 'Role',
        inlineAdd: ({errors, handleBlur, handleChange}) => {
          return <SelectInput className="col-3" name="role" title="" options={roleOptions} isValid={!errors.role} invalidWarning={errors.role} handleBlur={handleBlur} handleChange={handleChange} />
        },
        formatter: (member: AccountQuery['account']['members']['items'][0]) => {
          if (!member.role) {
            return 'Unknown'
          }

          return FormUtility.formatRoleLabel(member.role)
        }
      },
      {
        label: 'Activity',
        className: '',
        formatter: (member: AccountQuery['account']['members']['items'][0]) => {
          return (
            <>
              {'ACTIVE' === member.status && (
                <span>
                  <Tooltip className="mr-25" tooltip={Utility.formatFromUnixTime(member.joinedAt, '')} width={200}>
                    <PolyIcon icon="info_outline" size={15} />
                  </Tooltip>
                  <span>Joined {Utility.relativeFromUnixTime(member.joinedAt, 'date unknown', false)}</span>
                  <br />
                  {!member.lastLoginAt && <span>Never logged in</span>}
                  {member.lastLoginAt && (
                    <>
                      <Tooltip className="mr-25" tooltip={Utility.formatFromUnixTime(member.lastLoginAt, '')} width={200}>
                        <PolyIcon icon="info_outline" size={15} />
                      </Tooltip>
                      <span>Last login {Utility.relativeFromUnixTime(member.lastLoginAt, 'unknown', false)}</span>
                    </>
                  )}
                </span>
              )}
              {'PENDING' === member.status && (
                <>
                  <Tooltip className="mr-25" tooltip={Utility.formatFromUnixTime(member.inviteEmailedAt, '')} width={200}>
                    <PolyIcon icon="info_outline" size={15} />
                  </Tooltip>
                  <span>Invited {Utility.relativeFromUnixTime(member.inviteEmailedAt, 'unknown', false)}</span>
                </>
              )}
            </>
          )
        }
      },
      {
        label: 'Expires',
        // cannot expire today (as of midnight), so the minimum expiration is tomorrow 12am
        inlineAdd: <DateTimePickerInput min={startOfDay(addDays(new Date(), 1))} name="expireAccessAt" />,
        formatter: (member: AccountQuery['account']['members']['items'][0]) => {
          const timeDefault = 'Never'
          if (!member.expireAccessAt) {
            return timeDefault
          }

          return Utility.relativeFromUnixTime(member.expireAccessAt, timeDefault)
        }
      },
      {
        label: 'Actions',
        // CSS note: .showOnHover will not affect anything if direct child of .inline-add
        className: 'showOnHover actions',
        inlineAdd: ({hasErrors}) => (
          <button type="submit" className="button button-sm mt-0 invite" disabled={hasErrors}>
            Invite
          </button>
        ),
        formatter: (member: AccountQuery['account']['members']['items'][0]) => {
          const isPending = 'PENDING' === member.status
          let resendInviteDisabled = true
          let resendInviteTooltip = 'Resend Invite'
          let resendInviteTooltipWidth = 100

          if (isPending) {
            // all stamps considered in seconds
            const disabledUntil = member.inviteEmailedAt ? member.inviteEmailedAt + REACT_APP_MIRROR_INVITE_COOLDOWN_SECONDS : debouncedStamp + REACT_APP_MIRROR_INVITE_COOLDOWN_SECONDS
            resendInviteDisabled = debouncedStamp <= disabledUntil

            if (resendInviteDisabled) {
              const minutesLeft = Math.floor((disabledUntil - debouncedStamp) / 60)
              let secondsLeft = (disabledUntil - debouncedStamp - minutesLeft * 60).toString()
              if (+secondsLeft < 10) {
                secondsLeft = '0' + secondsLeft
              }
              resendInviteTooltip = `Please wait ${minutesLeft}:${secondsLeft} to resend this invite.`
              resendInviteTooltipWidth = 220
            }
          }

          return (
            <>
              {isPending && (
                <Tooltip tooltip={resendInviteTooltip} width={resendInviteTooltipWidth}>
                  <button
                    className="action info mr-5"
                    // className="action info"
                    // this is critical, otherwise pushing enter in an inlineAdd row will trigger this button,
                    // thinking it is a submit button
                    type="button"
                    disabled={resendInviteDisabled}
                    onClick={(e: SyntheticEvent) => {
                      e.stopPropagation()
                      RemoteData.handleResendInvite(executeResendInvite, {
                        account: props.account,
                        email: member.email
                      }).then(() => props.executeAccountQuery())
                      // executeAccountQuery refreshes the latest inviteEmailedAt,
                      // required for disabling resendInvite based on cooldown period
                    }}
                  >
                    <PolyIcon title="Resend Invite" icon="refresh_1" size="xxs" />
                  </button>
                </Tooltip>
              )}
              <Tooltip tooltip="Move Member" width={150}>
                <Dropdown
                  isOpen={isOpen}
                  onClose={toggle}
                  target={
                    <button className="action info mr-5" type="button" onClick={toggle}>
                      <PolyIcon title="Move" icon="arrow_forward" size="xxs" />
                    </button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{DropdownIndicator, IndicatorSeparator: null}}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen
                    onChange={option => {
                      const fromAccount = props.account
                      const toAccount = option.value
                      const email = member.email
                      RemoteData.handleMoveItem(executeMoveMember, {fromAccount, toAccount, email}, `${email} to account ${option.label}`, 'member', 'data.moveMember').then(() => props.executeAccountQuery())
                    }}
                    options={options}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                  />
                </Dropdown>
              </Tooltip>
              <Tooltip tooltip="Remove Member" width={150}>
                <button
                  className="action warning"
                  // this is critical, otherwise pushing enter in an inlineAdd row will trigger this button,
                  // thinking it is a submit button
                  type="button"
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation()
                    RemoteData.handleRemoveItem(executeRemoveMember, {account: props.account, email: member.email}, member.email, 'member', 'data.removeMember').then(() => props.executeAccountQuery())
                  }}
                >
                  <PolyIcon title="Delete" icon="trash" size="xxs" />
                </button>
              </Tooltip>
            </>
          )
        }
      }
    ]
  }

  return (
    <Formik
      initialValues={
        {
          email: '',
          account: props.account,
          expireAccessAt: null as number | null | Date,
          role: _get(roleOptions, [0, 'value'], undefined)
        } as AddMemberInput
      }
      onSubmit={async values => {
        // do not modify values.expireAccessAt directly, to prevent form errors
        const submitValues = _cloneDeep(values)

        // convert Date to timestamp
        if (submitValues.expireAccessAt && (submitValues.expireAccessAt as any) instanceof Date) {
          submitValues.expireAccessAt = ((submitValues.expireAccessAt as unknown) as Date).getTime() / 1000
        }

        RemoteData.handleAddMember(executeAddMemberMutation, submitValues).then(() => {
          // refresh data
          props.executeAccountQuery()
        })
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required().email('Enter a valid email address')
      })}
    >
      {formikProps => {
        const {values, errors, touched, handleChange, handleSubmit} = formikProps
        const hasErrors = !!Object.keys(errors).length

        return (
          <Form onSubmit={handleSubmit}>
            <EntityManager
              executeListQuery={props.executeListQuery}
              formikObject={{values, errors, hasErrors, handleChange, touched}}
              inlineAdd={true}
              // based on graphql.queries.accounts
              listQuery={props.listQuery}
              listQueryAccessor={props.listQueryAccessor}
              listFormatter={listFormatter}
              namePlural={namePlural}
              nameSingular={nameSingular}
              pagination={props.pagination ?? undefined}
              tableClassName="members"
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export {AccountMembers}
