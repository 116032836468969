import React from 'react'
import './Landing.scss'
import {useDispatch, useSelector} from 'react-redux'
import {Card} from '../../components/Card/Card'
import {Page} from '../../components/Common/Page'
import {Abstract} from '../../elements/Abstract'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import {useQuery} from '../../graphql/graphqlClient'
import {State} from '../../store'
import {changeBusy} from '../../store/layout/actions'
import {get as _get, has as _has} from 'lodash-es'
import {Utility} from '../../utils/Utility'
import {graphql} from '@mirror/dataplane'

interface IComponentProps {}

const Landing: React.FC<IComponentProps> = props => {
  const [deviceCountQuery] = useQuery(graphql.queries.deviceMetrics)
  const [memberCountQuery] = useQuery(graphql.queries.userMetrics)
  const [accountCountQuery] = useQuery(graphql.queries.accountMetrics)

  const dispatch = useDispatch()
  dispatch(changeBusy(deviceCountQuery.fetching || memberCountQuery.fetching || accountCountQuery.fetching))

  const devices = deviceCountQuery.fetching ? <Abstract display="inline-block" layout={['large-number']} /> : _get(deviceCountQuery, 'data.deviceMetrics.count', 0)
  const members = memberCountQuery.fetching ? <Abstract display="inline-block" layout={['large-number']} /> : _get(memberCountQuery, 'data.userMetrics.count', 0)
  const accounts = accountCountQuery.fetching ? <Abstract display="inline-block" layout={['large-number']} /> : _get(accountCountQuery, 'data.accountMetrics.count', 0)

  /* TODO https://onecode.polycom-labs.com/mono/core/issues/53 hide stubbed data until API calls support data */
  const cards = [
    {
      name: 'Accounts',
      icon: 'conference_large',
      link: '/accounts',
      stat: Utility.formatWholeNumber(accounts),
      statLabel: '',
      className: 'muted'
    },
    {
      name: 'Devices',
      icon: 'device',
      link: '/devices',
      stat: Utility.formatWholeNumber(devices),
      statLabel: 'active',
      // changePercent: 16,
      // changeLabel: 'since last week',
      className: 'muted'
    }
  ] as any[]

  const products = cards.map((card, i) => {
    const contentClasses: string[] = []
    let change
    let hasChange = false
    let increase = false
    let decrease = false

    if (card && card.changePercent && card.changeLabel) {
      hasChange = true
      if (card.changePercent >= 0) {
        change = 'increase'
        increase = true
      } else {
        change = 'decrease'
        decrease = true
      }

      contentClasses.push('hasChangeInfo')
      contentClasses.push(change)
    }

    const changeContent = (
      <div className="change text-center">
        <span className="changePercent mr-25">
          {hasChange && increase && <PolyIcon icon="arrow_drop_up" size={19} />}
          {hasChange && decrease && <PolyIcon icon="arrow_drop_down" size={19} />}
          {card.changePercent}%
        </span>
        <span className="changeLabel">{card.changeLabel}</span>
      </div>
    )

    return (
      <Card className="landing" to={card.link} key={i} index={i} card={card} contentClasses={contentClasses.join(' ')}>
        {/* if decrease, change goes at top */}
        {hasChange && decrease && changeContent}
        {_has(card, 'stat') && null !== card.stat && (
          <div className="stat pr-25">
            {card.stat}
            {card.statLabel && <label>{card.statLabel}</label>}
          </div>
        )}
        {hasChange && increase && changeContent}
      </Card>
    )
  })

  const userData = useSelector((state: State) => state.login.userData)

  return (
    <Page grid={false} title={<>Hello {userData.firstName}</>} subtitle="Let's get you caught up." className="landing-page text-center">
      {products}
      {/* TODO https://onecode.polycom-labs.com/mono/core/issues/53 hide stubbed data until aggregation/API calls support it
      <Earth/>
      */}
    </Page>
  )
}

export {Landing}
