import React from 'react'

interface IComponentProps {
  layout: ('xs' | 'sm' | 'sm-square' | 'sm-square-center' | 'md' | 'lg' | 'full' | 'spacer' | 'large-number')[]
  display?: 'block' | 'inline-block'
}

/**
 * Abstract loading indicators.
 *
 * @param props
 */
const Abstract: React.FC<IComponentProps> = props => {
  const display = props.display || 'block'

  return (
    <>
      {props.layout &&
        props.layout.map((size, i) => <div key={i} className={`abstract abstract-${display} abstract-${size}`} />)}
    </>
  )
}

export {Abstract}
