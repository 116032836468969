import React from 'react'
import {Auth} from 'aws-amplify'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import './Login.scss'

interface IComponentProps {
  hideButton: boolean
  messageClasses: string
}

/**
 * See Invitation.tsx for example usage with message.
 *
 * @param props
 * @constructor
 */
const Login: React.FC<IComponentProps> = props => (
  <div className="login">
    <div className="intro">
      <PolyIcon className="polymark" customIcon="poly_mark" size={200} />
      <PolyIcon className="polyname" customIcon="poly_name" overrideDimensions={true} />
      <div className="mirror-wrapper">
        <div className="mirror">mirror</div>
      </div>
    </div>
    {props.children && <div className={props.messageClasses}>{props.children}</div>}
    {true !== props.hideButton && (
      <div className="welcome">
        <button className="button" onClick={() => Auth.federatedSignIn({customProvider: 'Microsoft'})}>
          Login
        </button>
      </div>
    )}
  </div>
)

export {Login}
