import React from 'react'
import {Field} from 'formik'
import './SwitchInput.scss'

interface ISwitchInput {
  name: string
  title?: string
  label: string
}

/**
 * Supports a boolean (for example, on/off button) kind of checkbox handling.
 *
 * For multiple check options, see the CheckboxInput component.
 *
 * Requires only one option in props.options, and only regards label (disregards the object's value).
 * This will only set a true or false value.
 * e.g. options={[{label: 'Enabled'}]}
 *
 * @param props
 * @constructor
 */
const SwitchInput = (props: ISwitchInput) => {
  /*
      <span
      className={["toggle-switch-wrapper", className]
        .filter(c => !!c)
        .join(" ")}
    >
      <input
        id={id || name}
        name={name}
        data-testid={`${id || name}-input`}
        onChange={onChange}
        ref={inputRef}
        aria-label={label}
        type="checkbox"
        {...otherProps}
      />
      <label
        className="toggle-switch-indicator"
        htmlFor={id || name}
        data-testid={`${id || name}-label`}
      />
    </span>
   */

  const generatedID = `${props.name}-0`
  // For this checkbox method, see also @help https://codesandbox.io/s/328038x19q?file=/src/index.js
  return (
    <div className="form-group switch-input">
      <label>{props.title}</label>
      <span className="form-option">
        <Field name={props.name}>
          {({field, form}) => {
            return (
              <input
                type="checkbox"
                className="form-checkbox"
                id={generatedID}
                name={props.name}
                checked={field.value}
                onChange={() => {
                  // just flip value
                  const nextValue = !field.value
                  form.setFieldValue(props.name, nextValue)
                }}
              />
            )
          }}
        </Field>
        <label htmlFor={generatedID} data-text={props.label} />
      </span>
    </div>
  )
}

export default SwitchInput
