import {ActionType} from 'typesafe-actions'
import * as loginActions from './actions'
import {CognitoUser} from '@aws-amplify/auth'

export enum LoginActionType {
  changeAuthState = 'CHANGE_AUTH_STATE',
  // includes CognitoUser, name, firstName, lastName etc
  changeUserData = 'CHANGE_USER_DATA'
}

export type LoginAction = ActionType<typeof loginActions>

export interface UserData {
  data: CognitoUser
  name: string
  email: string
  firstName: string
  lastName: string
  initials: string
  role: string
}

export interface UserDataStateChange {
  data: CognitoUser | undefined
  // TODO uncertain what error typing is thrown by AWS Amplify, make more specific if possible
  // @help https://aws-amplify.github.io/docs/js/authentication#retrieve-current-authenticated-user
  error: any | undefined
}

// combine all states and export
export interface LoginState {
  authState: 'signedIn' | 'signIn'
  userData: UserData
}
