import React from 'react'
import './Operations.scss'
import {Account} from '../Accounts/Account'

interface IComponentProps {}

const Operations: React.FC<IComponentProps> = props => {
  return <Account match={{params: {id: 'operations'}}} hideProfile={true} hideAPI={true} isOperations={true}></Account>
}

export {Operations}
