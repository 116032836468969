import './Account.scss'
import React, {useState} from 'react'
import {Card} from '../../components/Card/Card'
import {Page} from '../../components/Common/Page'
import {useQuery} from '../../graphql/graphqlClient'
import {graphql} from '@mirror/dataplane'
import {OptimizelyFeature} from '@optimizely/react-sdk'
import {Redirect} from 'react-router'
import {changeBusy} from '../../store/layout/actions'
import {useDispatch} from 'react-redux'
import {useMutation} from 'urql'
import {Tabs} from '../../components/Tab/Tabs'
import {Tab} from '../../components/Tab/Tab'
import {useDataPagination} from '../../utils/UseDataPagination'
import {AccountAPIForm} from './AccountAPIForm'
import {AccountMembers} from './AccountMembers'
import {AccountProfileForm} from './AccountProfileForm'

interface IComponentProps {
  // NOTE this is set in either routes.tsx or manually set, e.g. by direct call in Operations.tsx
  match: {
    params: {
      id: 'new' | string
    }
  }
  // ability to hide tabs on certain pages
  hideProfile?: boolean
  hideAPI?: boolean
  hideMembers?: boolean
  // if this is the operations account, pass through to AccountMembers component
  isOperations?: true
}

/**
 * Component to add or edit an account.  Form has sections so that areas can be segmented & subdivided,
 * for instance Zoom.us's small account sections instead of having one large, multi-page form.
 *
 * @param props
 * @constructor
 */
const Account: React.FC<IComponentProps> = props => {
  const {
    match: {params}
  } = props
  const dispatch = useDispatch()

  const nameSingular = 'Account'
  const id = params.id
  const isNew = 'new' === id
  let hideAPI = props.hideAPI || false
  let hideMembers = props.hideMembers || false
  if (isNew) {
    hideAPI = true
    hideMembers = true
  }
  const saveVerb = isNew ? 'Create' : 'Update'
  const savingVerb = isNew ? 'Creating' : 'Updating'

  // third parameter is a query pause; no reason to query for account and members if this is a new account
  const [accountQuery, executeAccountQuery] = useQuery(graphql.queries.account, {id}, isNew)
  const [updateAccountMutation, executeUpdateAccountMutation] = useMutation(graphql.mutations.updateAccount)
  const [, executeCreateAccountMutation] = useMutation(graphql.mutations.createAccount)
  const [memberVariables, setMemberVariables] = useState({
    account: id,
    limit: 10,
    cursor: undefined as undefined | string
  })
  const membersPagination = useDataPagination(
    graphql.queries.members,
    memberVariables,
    setMemberVariables,
    'members.items',
    'members.next'
  )
  const {query: membersQuery, executeQuery: executeMembersQuery} = membersPagination

  const title = isNew ? `New ${nameSingular}` : accountQuery.data?.account?.name
  const subtitle = isNew ? undefined : 'Account'

  dispatch(changeBusy(accountQuery.fetching))

  if (!isNew && accountQuery.fetching) {
    // do not render if account data still fetching
    return <></>
  }

  const saveButton = (disabled: boolean) => {
    return (
      <div className="mt-1">
        {!updateAccountMutation.fetching && (
          <button className="button button" type="submit" disabled={disabled}>
            {saveVerb} Account
          </button>
        )}
        {updateAccountMutation.fetching && (
          <button className="button button" type="submit" disabled={true}>
            {savingVerb}...
          </button>
        )}
      </div>
    )
  }

  return (
    <>
      {!accountQuery.fetching && (
        <>
          <OptimizelyFeature feature="mono_mirror_manage_accounts_users">
            {isEnabled => !isEnabled && <Redirect to="/"></Redirect>}
          </OptimizelyFeature>
          <Page className="text-center" title={title} subtitle={subtitle}>
            <Card className="label-layout label-layout-full col-12 col-xl-10 offset-xl-1 p-1 auto">
              <Tabs>
                {!props.hideProfile && (
                  <Tab id="profile" label="Profile">
                    <AccountProfileForm
                      id={id}
                      isNew={isNew}
                      saveButton={saveButton}
                      accountQuery={accountQuery}
                      executeAccountQuery={executeAccountQuery}
                      executeCreateAccountMutation={executeCreateAccountMutation}
                      executeUpdateAccountMutation={executeUpdateAccountMutation}
                      parentProps={props}
                    />
                  </Tab>
                )}
                {!hideMembers && (
                  <Tab label="Members" id="members">
                    {/* Pass-through query since members is a sub-query */}
                    <AccountMembers
                      account={id}
                      listQuery={membersQuery}
                      executeListQuery={executeMembersQuery}
                      listQueryAccessor="members"
                      executeAccountQuery={executeMembersQuery}
                      isOperations={props.isOperations}
                      pagination={membersPagination}
                    />
                    {/* note no save button here, changes take place immediately */}
                  </Tab>
                )}
                {!hideAPI && (
                  <Tab label="API" id="api" labelClassName={'link-class-1'}>
                    <AccountAPIForm
                      id={id}
                      isNew={isNew}
                      saveButton={saveButton}
                      accountQuery={accountQuery}
                      executeAccountQuery={executeAccountQuery}
                      executeUpdateAccountMutation={executeUpdateAccountMutation}
                    />
                  </Tab>
                )}
              </Tabs>
            </Card>
          </Page>
        </>
      )}
    </>
  )
}

export {Account}
