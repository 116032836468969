import React from 'react'
import {Field} from 'formik'
import {Utility} from '../../utils/Utility'
import {FormUtility} from './FormUtility'

interface ISelectInput {
  name: string
  title: string
  className?: string
  options: any
  handleChange: any
  handleBlur: any
  isValid?: boolean
  invalidWarning?: string
  disabled?: boolean
}

const SelectInput = (props: ISelectInput) => {
  let classes = Utility.classNames(['form-group', props.className])

  const [isValid, invalidElement] = FormUtility.getValidationFromProps(props)
  classes += isValid ? ' valid' : ' invalid'

  return (
    <div className={classes}>
      {props.title && (
        <label htmlFor={props.name} className="form-label">
          {props.title}
        </label>
      )}
      <Field name={props.name}>
        {({field}) => {
          return (
            <select
              className="form-control form-input"
              id={props.name}
              name={props.name}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={field.value}
              disabled={props.disabled}
            >
              {props.options.map((option, index) => {
                const id = `${props.name}-${index}`
                const checked = option.value === field.value

                return (
                  <option className="form-option" key={index} id={id} value={option.value} defaultChecked={checked}>
                    {option.label}
                  </option>
                )
              })}
            </select>
          )
        }}
      </Field>
      {invalidElement}
    </div>
  )
}

export {SelectInput}
