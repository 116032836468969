import {ActionType} from 'typesafe-actions'
import * as layoutActions from './actions'

export type LayoutAction = ActionType<typeof layoutActions>

export interface LayoutState {
  profileVisible: boolean
  menuVisible: boolean
  busy: boolean
}

export enum LayoutActionType {
  changeProfileVisible = 'CHANGE_PROFILE_VISIBLE',
  changeMenuVisible = 'CHANGE_MENU_VISIBLE',
  changeBusy = 'CHANGE_BUSY'
}
