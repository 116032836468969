import React, {useState, SyntheticEvent} from 'react'
import {flatten as _flatten, get as _get, kebabCase as _kebabCase} from 'lodash-es'
import {GraphQLError} from '../../elements/GraphQLError'
import './EntityManager.scss'
import {FormikErrors, FormikValues, FormikTouched} from 'formik'
import {Utility} from '../../utils/Utility'
import {paginationControls} from '../Common/PaginationControls'

interface IComponentProps {
  executeListQuery: any
  formikObject?: {
    values?: FormikValues
    errors?: FormikErrors<any>
    hasErrors: boolean
    touched?: FormikTouched<any>
    handleChange?: any
    handleBlur?: any
  }
  // if true, adds an option for inline add at the end of the table entries
  // requires every fields[n].inlineAdd ReactElement to be present
  inlineAdd?: boolean
  listFormatter: any
  listHeader?: React.ReactElement
  listQuery: any
  listQueryAccessor: string
  nameSingular: string
  namePlural: string
  newItemButton?: React.ReactElement
  onClickRow?: (e: SyntheticEvent, item: any) => void
  tableClassName?: string
  pagination?: any
}

interface IField {
  className?: string
  formatter: (item: any) => string | React.ReactElement
  // TODO
  // inlineAdd?: (IComponentProps['formikObject']) => React.ReactElement | React.ReactElement
  inlineAdd?: any
  // column inline style, for instance {minWidth: '150px'}; recommended to use .col-expires
  style?: object
  label: string
  // hide label in large table view; in responsive (small) tables, label is always shown
  hideColumnLabel?: true
}

/**
 * EntityManager assists with the common task of viewing a list of items and
 * paginating through them.  Actions such as edit and delete are implemented by the calling
 * component.  At some point, it may make sense to bring those edit and delete features
 * into EntityManager, but need to wait until coding patterns emerge to implement that
 * in EntityManager.
 *
 * @param props
 * @constructor
 */
const EntityManager: React.FC<IComponentProps> = props => {
  // const [form, setForm] = useState(_cloneDeep(props.newForm))
  // const [formSaveError, setFormSaveError] = useState<string | undefined>()
  // const [formSaveSuccess, setFormSaveSuccess] = useState<string | undefined>()
  const [removeSuccess] = useState<string | undefined>()
  const [removeFailure] = useState<string | undefined>()
  const listQuery = props.listQuery
  // get deep path of listQuery.data
  const listQueryItems = _get(listQuery, _flatten(['data', props.listQueryAccessor.split('.'), 'items']))
  const fieldsLength = props.listFormatter.fields?.length
  const tableClassNames = Utility.classNames(['table', 'rounded', props.tableClassName])

  return (
    <>
      {props.listHeader}
      <GraphQLError respondTo={listQuery} />
      {removeFailure && <p className="warning">{removeFailure}</p>}
      {removeSuccess && <p className="success">{removeSuccess}</p>}
      {props.newItemButton && props.newItemButton}
      <table className={tableClassNames}>
        <thead>
          <tr>
            {props.listFormatter.fields.map((field: IField, index: number) => {
              // convert label of 'Device Status' into a className of 'col-device-status'
              const classes = Utility.classNames([_kebabCase(`col-${field.label}`)])

              return (
                <th className={classes} key={index}>
                  {!field.hideColumnLabel && field.label}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {/* loading indicator while query is fetching */}
          {listQuery.fetching && (
            <tr key={0}>
              <td colSpan={fieldsLength}>Loading...</td>
            </tr>
          )}
          {/* format for no data returned,
                typically due to an error which will be displayed above table */}
          {!listQuery.fetching && !listQuery.data && (
            <tr key={1}>
              {props.listFormatter.fields.map((field: IField, index: number) => {
                // convert label of 'Device Status' into a className of 'col-device-status'
                const classes = Utility.classNames([_kebabCase(`col-${field.label}`)])

                return (
                  <td className={classes} key={index}>
                    <div className="abstract abstract-sm abstract-no-animation"></div>
                  </td>
                )
              })}
            </tr>
          )}
          {/* data is finished fetching, but no items found */}
          {!listQuery.fetching && 0 === listQueryItems?.length && (
            <tr key={2}>
              <td colSpan={fieldsLength}>
                {/* If paginating and there is a previous page, indicate "No More ____ Found".  This is because
                with DynamoDB there can be a next cursor, but when that cursor is queried there are no more entries. */}
                No {props.pagination?.hasPrevious && <>More </>}
                {props.namePlural} Found
              </td>
            </tr>
          )}
          {/* data fetched properly, display data */}
          {!listQuery.fetching &&
            listQueryItems &&
            listQueryItems.map((rowData, index) => {
              const onClickRow = (e: SyntheticEvent) => {
                if (props.onClickRow) {
                  props.onClickRow(e, rowData)
                }
              }
              const trClasses = [props.onClickRow ? 'pointer' : ''].join(' ')

              return (
                <tr key={index + 10} onClick={onClickRow} className={trClasses}>
                  {/* adding 10 to index to account for other tr indices that may be on screen */}
                  {props.listFormatter.fields.map((field: IField, index: number) => {
                    // convert label of 'Device Status' into a className of 'col-device-status'
                    const classes = Utility.classNames([field.className, _kebabCase(`col-${field.label}`)])

                    return (
                      <td key={index} className={classes} data-label={field.label}>
                        {field.formatter(rowData)}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          {/* last row of table allows adding additional items */}
          {props.inlineAdd && (
            <tr className="inline-add">
              {props.listFormatter.fields.map((field: IField, index: number) => {
                let className = field.className ? field.className : ''
                const inlineAdd =
                  'function' === typeof field.inlineAdd && props.formikObject
                    ? field.inlineAdd(props.formikObject)
                    : field.inlineAdd
                const style = field.style ?? {}

                return (
                  <td key={index} className={className} data-label={field.label} style={style}>
                    {inlineAdd}
                  </td>
                )
              })}
            </tr>
          )}
        </tbody>
      </table>
      {props.pagination && <div className="text-center">{paginationControls(props.pagination)}</div>}
    </>
  )
}

export {EntityManager}
