import {useState, useEffect} from 'react'
import {debounce as _debounce} from 'lodash-es'

/**
 * NOTE delay of 2000 milliseconds and {leading: true, trailing: true} will work instantly
 * (leading edge), will pause for at least 2 seconds, then push a new value (trailing edge)
 * if it has changed.  If it didn't change, it will just hit once for the initial value.
 * This works well for expensive operations - the user has immediate access to an API result,
 * but then if input keeps changing it will wait until input pauses for delay milliseconds
 * before hitting the API again.
 *
 * @param value
 * @param delay
 * @param options - see https://lodash.com/docs/4.17.15#debounce
 */
const useDebounce = (
  value: any,
  delay: number,
  options: {leading?: boolean; maxWait?: number; trailing?: boolean} = {}
) => {
  // state and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  const [debouncedFn] = useState(() =>
    _debounce(
      (newValue: any) => {
        setDebouncedValue(newValue)
      },
      delay,
      options
    )
  )

  useEffect(() => {
    debouncedFn(value)
  }, [value, debouncedFn])

  return debouncedValue
}

export {useDebounce}
