import {compact as _compact, difference as _difference, get as _get, map as _map, toLower as _toLower} from 'lodash-es'
import {Component} from 'react'
import {toastr} from 'react-redux-toastr'
import {UseQueryState} from 'urql'
import {deviceFamilies, deviceModels, deviceProviders} from '../graphql/customQueries'
import {useQuery} from '../graphql/graphqlClient'
import {Notification} from './Notification'
import {Utility} from './Utility'
import {AddMemberInput, ResendInviteInput} from '@mirror/dataplane/build/graphql'

class RemoteData extends Component {
  /**
   * Spawn a request for deviceModelsQuery and return urql object and parsed deviceModelOptions.
   *
   * Note this only spawns one graphql request.
   */
  static getDeviceModelOptions = () => {
    const [deviceModelsQuery] = useQuery(deviceModels)

    // get enum values for valid operations roles
    let deviceModelOptions: any[] = [
      {
        label: 'All Device Models',
        value: ''
      }
    ]

    const REACT_APP_HIDE_TRIO = Utility.envVariable('REACT_APP_HIDE_TRIO', true, false)
    const REACT_APP_HIDE_VVX = Utility.envVariable('REACT_APP_HIDE_VVX', true, false)

    if (!deviceModelsQuery.error && deviceModelsQuery.data) {
      const enumValues = _get(deviceModelsQuery, 'data.__type.enumValues', [])
      if (!enumValues.length) {
        console.error('Unable to retrieve enum values for device models.')
      }

      deviceModelOptions = _compact(
        deviceModelOptions.concat(
          enumValues.map(enumValue => {
            const value = enumValue.name

            if (REACT_APP_HIDE_TRIO && 0 === value.search(/^TRIO/)) {
              return undefined
            }

            if (REACT_APP_HIDE_VVX && 0 === value.search(/^VVX/)) {
              return undefined
            }

            return {
              label: Utility.deviceReadable(enumValue.name),
              value
            }
          })
        )
      )
    }

    return [deviceModelsQuery as UseQueryState<any>, deviceModelOptions as any]
  }

  /**
   * Sanity check that all types needed by inferFamilyFromModel are in enums.
   * Does not return a value currently.
   */
  static checkDeviceFamilyValues = () => {
    const [deviceFamiliesQuery] = useQuery(deviceFamilies)

    if (!deviceFamiliesQuery.error && deviceFamiliesQuery.data) {
      let deviceFamilyValues = _get(deviceFamiliesQuery, 'data.__type.enumValues', [])
      let requiredEnumValues = ['GROUP', 'STUDIO', 'TRIO', 'VVX']
      deviceFamilyValues = _map(deviceFamilyValues, o => o.name)
      const missingEnumValues = _difference(deviceFamilyValues, requiredEnumValues)
      if (missingEnumValues.length > 0) {
        console.error('Expected family enum values', requiredEnumValues, 'but only received', deviceFamilyValues, '. This could indicate a breaking schema change.')
      }
    }

    return [deviceFamiliesQuery as UseQueryState<any>]
  }

  static getDeviceProfileOptions = (defaultLabel = 'Select a Profile') => {
    // get enum values for valid roles
    let deviceProfileOptions: any[] = [
      {
        label: defaultLabel,
        value: ''
      }
    ]
    const [deviceProfilesQuery] = useQuery(deviceProviders)

    if (!deviceProfilesQuery.error && deviceProfilesQuery.data) {
      const enumValues = _get(deviceProfilesQuery, 'data.__type.enumValues', [])
      if (!enumValues.length) {
        console.error('Unable to retrieve enum values for device base profiles.')
      }

      deviceProfileOptions = _compact(
        deviceProfileOptions.concat(
          enumValues.map(enumValue => {
            return {
              label: Utility.profileReadable(enumValue.name),
              value: enumValue.name
            }
          })
        )
      )
    }

    return [deviceProfilesQuery as UseQueryState<any>, deviceProfileOptions as any]
  }

  /**
   * Handle unregistering a device.
   *
   * @param executeUnregisterDevice - function generated from useMutation(...)
   * @param device - device to operate on
   */
  static handleUnregisterDevice = (executeUnregisterDevice: any, device: any) => {
    return new Promise((resolve, reject) => {
      toastr.confirm(`Unregister this device? Serial Number ${device.serial}, Mac ${device.mac}`, {
        okText: 'Unregister',
        cancelText: 'Cancel',
        onCancel: () => reject('User cancelled unregister operation.'),
        onOk: () => {
          executeUnregisterDevice({input: {id: device.id}}).then(res => {
            let error = _get(res, 'error.message')

            if (error) {
              error = error.replace(/\[GraphQL\]/g, '')
              error = `Could not remove device.  Returned error: ${error}`

              Notification.error(error)
              reject(error)
            } else if (true === _get(res, 'data.unregisterDevice')) {
              Notification.success('Successfully removed device.')

              resolve()
            } else {
              error = 'Could not remove device.  Unknown error.'
              Notification.error(error)

              reject(error)
            }

            // TODO add an "optimistic response" on successful deletion so that device
            // is removed then query runs again, otherwise device listing 'flashes' before item is removed
          })
        }
      } as any)
    })
  }

  /**
   * Handle locking and unlocking a device.
   *
   * @param execute - function generated from useMutation(...)
   * @param device - device to operate on
   * @param lock
   */
  static handleLockAndUnlockDevice = (execute: any, device: any, lock: boolean) => {
    return new Promise((resolve, reject) => {
      const verb = lock ? 'Lock' : 'Unlock'
      const verb_lower = _toLower(verb)

      toastr.confirm(`${verb} this device?`, {
        okText: verb,
        cancelText: 'Cancel',
        onCancel: () => reject(`User cancelled ${verb_lower} operation.`),
        onOk: () => {
          execute({input: {id: device.id}}).then(res => {
            let error = _get(res, 'error.message')

            if (error) {
              error = error.replace(/\[GraphQL\]/g, '')
              error = `Could not ${verb_lower} device.  Returned error: ${error}`

              Notification.error(error)
              reject(error)
            } else if (lock === _get(res, ['data', verb_lower + 'DeviceProfile', 'locked'])) {
              Notification.success(`Successfully ${verb_lower}ed device.`)

              resolve()
            } else {
              error = `Could not ${verb_lower} device.  Unknown error.`
              Notification.error(error)

              reject(error)
            }
          })
        }
      } as any)
    })
  }

  /**
   * Handle setting a device profile.
   *
   * @param executeSetDeviceProfile - function generated from useMutation(...)
   * @param device - device to operate on
   * @param profile - currently only ZOOM is supported by API
   */
  static handleSetDeviceProfile = (executeSetDeviceProfile: any, device: any, profile: string) => {
    return new Promise((resolve, reject) => {
      executeSetDeviceProfile({input: {id: device.id, provider: profile}}).then(res => {
        let error = _get(res, 'error.message')

        if (error) {
          error = error.replace(/\[GraphQL\]/g, '')
          error = `Could not set profile.  Returned error: ${error}`

          Notification.error(error)
          reject(error)
        } else {
          let remoteProfile = _get(res, 'data.setDeviceProfile.provider')
          if (profile === remoteProfile) {
            const profileReadable = Utility.profileReadable(remoteProfile)
            Notification.success(`Successfully set profile to ${profileReadable}.`)

            resolve()
          } else {
            error = 'Could not set profile.  Unknown error.'
            Notification.error(error)

            reject(error)
          }
        }

        // TODO add an "optimistic response" on successful deletion so that device
        // is removed then query runs again, otherwise device listing 'flashes' before item is removed
      })
    })
  }

  /**
   * Handle unsetting a device profile.
   *
   * @param executeUnsetDeviceProfile - function generated from useMutation(...)
   * @param device - device to operate on
   */
  static handleUnsetDeviceProfile = (executeUnsetDeviceProfile: any, device: any) => {
    return new Promise((resolve, reject) => {
      // note confirmation does not include serial number
      toastr.confirm(`Remove profile for this device?`, {
        okText: 'Remove Profile',
        cancelText: 'Cancel',
        onCancel: () => reject('User cancelled unregister operation.'),
        onOk: () => {
          executeUnsetDeviceProfile({input: {id: device.id}}).then(res => {
            let error = _get(res, 'error.message')

            if (error) {
              error = error.replace(/\[GraphQL\]/g, '')
              error = `Could not remove profile.  Returned error: ${error}`

              Notification.error(error)
              reject(error)
            } else if (null === _get(res, 'data.unsetDeviceProfile')) {
              Notification.success('Successfully removed profile.')

              resolve()
            } else {
              error = 'Could not remove profile.  Unknown error.'
              Notification.error(error)

              reject(error)
            }

            // TODO add an "optimistic response" on successful deletion so that device
            // is removed then query runs again, otherwise device listing 'flashes' before item is removed
          })
        }
      } as any)
    })
  }

  /**
   * Handle unsetting a device profile.
   *
   * @param executeQuarantineDevice - function generated from useMutation(...)
   * @param device - device to operate on
   */
  static handleQuarantineDevice = (executeQuarantineDevice: any, device: any) => {
    return new Promise((resolve, reject) => {
      executeQuarantineDevice({input: {id: device.id}}).then(res => {
        let error = _get(res, 'error.message')

        if (error) {
          error = error.replace(/\[GraphQL\]/g, '')
          error = `Could not quarantine device.  Returned error: ${error}`

          Notification.error(error)
          reject(error)
        } else if ('QUARANTINED' === _get(res, 'data.quarantineDevice.status')) {
          Notification.success('Successfully quarantined device.')

          resolve()
        } else {
          error = 'Could not quarantine device.  Unknown error.'
          Notification.error(error)

          reject(error)
        }

        // TODO add an "optimistic response" on successful deletion so that device
        // is removed then query runs again, otherwise device listing 'flashes' before item is removed
      })
    })
  }

  // TODO make this file much DRYer by combining error handling

  /**
   * Handle removing device quarantine.
   *
   * @param executeUnquarantineDevice - function generated from useMutation(...)
   * @param device - device to operate on
   */
  static handleUnquarantineDevice = (executeUnquarantineDevice: any, device: any) => {
    return new Promise((resolve, reject) => {
      executeUnquarantineDevice({input: {id: device.id}}).then(res => {
        let error = _get(res, 'error.message')

        if (error) {
          error = error.replace(/\[GraphQL\]/g, '')
          error = `Could not remove device quarantine.  Returned error: ${error}`

          Notification.error(error)
          reject(error)
        } else if ('QUARANTINED' !== _get(res, 'data.unquarantineDevice.status')) {
          Notification.success('Successfully removed device quarantine.')

          resolve()
        } else {
          error = 'Could not quarantine device.  Unknown error.'
          Notification.error(error)

          reject(error)
        }

        // TODO add an "optimistic response" on successful deletion so that device
        // is removed then query runs again, otherwise device listing 'flashes' before item is removed
      })
    })
  }

  /**
   * Handle creating or updating an account.
   *
   * @param executeMutation - function generated from useMutation(...), may be a create or update account mutation
   * @param account
   */
  static handleCreateOrUpdateAccount = (executeMutation: any, account: any) => {
    return new Promise((resolve, reject) => {
      executeMutation({input: account}).then(res => {
        const isNew = !account.id
        const verb = isNew ? 'create' : 'update'
        const verbPastTense = `${verb}d`
        let error = _get(res, 'error.message')

        if (error) {
          error = error.replace(/\[GraphQL\]/g, '')
          error = `Could not ${verb} account.  Returned error: ${error}`

          Notification.error(error)
          reject(error)
        } else {
          const redirectMessage = isNew ? ` Redirecting to new account...` : ''
          Notification.success(`Successfully ${verbPastTense} account.${redirectMessage}`)
          const id = isNew ? (_get(res, 'data.createAccount.id', '') as string) : (_get(res, 'data.updateAccount.id', '') as string)

          resolve(id)
        }
      })
    })
  }

  /**
   * Handle moving an user.
   *
   * @param execute - function generated from useMutation(...)
   * @param input - input for execute mutation, e.g. {id: '12345'}
   * @param itemName - specific name of item, for removal confirmation purposes, e.g. "ryan.mckeel@poly.com"
   * @param type - simple text label for notifications, e.g. 'member'
   * @param successResponsePath - path in response request to check for success, e.g. 'data.removeAccount'
   */
  static handleMoveItem = (execute: any, input: any, itemName: string, type: string, successResponsePath: string) => {
    return new Promise((resolve, reject) => {
      toastr.confirm(`Move ${type} ${itemName}?`, {
        okText: 'Move',
        cancelText: 'Cancel',
        onCancel: () => reject('User cancelled remove operation.'),
        onOk: () => {
          execute({input}).then(res => {
            let error = _get(res, 'error.message')

            if (error) {
              error = error.replace(/\[GraphQL\]/g, '')
              error = `Could not move ${type}.  Returned error: ${error}`

              Notification.error(error)
              reject(error)
            } else if (true === _get(res, successResponsePath)) {
              Notification.success(`Successfully moved ${type}.`)

              resolve()
            } else {
              error = `Could not move ${type}.  Unknown error.`
              Notification.error(error)

              reject(error)
            }
          })
        }
      } as any)
    })
  }

  /**
   * Handle removing an item.
   *
   * @param execute - function generated from useMutation(...)
   * @param input - input for execute mutation, e.g. {id: '12345'}
   * @param itemName - specific name of item, for removal confirmation purposes, e.g. "ryan.mckeel@poly.com"
   * @param type - simple text label for notifications, e.g. 'member'
   * @param successResponsePath - path in response request to check for success, e.g. 'data.removeAccount'
   */
  static handleRemoveItem = (execute: any, input: any, itemName: string, type: string, successResponsePath: string) => {
    return new Promise((resolve, reject) => {
      toastr.confirm(`Remove ${type} "${itemName}"?`, {
        okText: 'Remove',
        cancelText: 'Cancel',
        onCancel: () => reject('User cancelled remove operation.'),
        onOk: () => {
          execute({input}).then(res => {
            let error = _get(res, 'error.message')

            if (error) {
              error = error.replace(/\[GraphQL\]/g, '')
              error = `Could not remove ${type}.  Returned error: ${error}`

              Notification.error(error)
              reject(error)
            } else if (true === _get(res, successResponsePath)) {
              Notification.success(`Successfully removed ${type}.`)

              resolve()
            } else {
              error = `Could not remove ${type}.  Unknown error.`
              Notification.error(error)

              reject(error)
            }
          })
        }
      } as any)
    })
  }

  /**
   * Handle adding a member to an account.
   *
   * @param executeMutation - function generated from useMutation(...), may be a create or update account mutation
   * @param member
   */
  static handleAddMember = (executeMutation: any, member: AddMemberInput) => {
    return new Promise((resolve, reject) => {
      executeMutation({input: member}).then(res => {
        const verb = 'create'
        const verbPastTense = `${verb}d`
        let error = _get(res, 'error.message')

        if (error) {
          error = error.replace(/\[GraphQL\]/g, '')
          error = `Could not ${verb} member.  Returned error: ${error}`

          Notification.error(error)
          reject(error)
        } else {
          Notification.success(`Successfully ${verbPastTense} member.`)

          resolve()
        }
      })
    })
  }

  /**
   * Handle resending an invite to a prospective member.
   *
   * @param executeMutation - function generated from useMutation(...), may be a create or update account mutation
   * @param member
   */
  static handleResendInvite = (executeMutation: any, input: ResendInviteInput): Promise<number> => {
    return new Promise((resolve, reject) => {
      executeMutation({input}).then(res => {
        const verb = 'resend invite'
        const verbPastTense = 'resent invite'
        let error = _get(res, 'error.message', '')

        if (!error && res?.data?.resendInvite?.inviteEmailedAt) {
          Notification.success(`Successfully ${verbPastTense} to ${input.email}.`)

          resolve(res.data.resendInvite.inviteEmailedAt)
        } else {
          error = error.replace(/\[GraphQL\]/g, '')
          error = `Could not ${verb} to ${input.email}.  Returned error: ${error}`

          Notification.error(error)
          reject(error)
        }
      })
    })
  }
}

export {RemoteData}
