import React from 'react'
import './RadioInput.scss'
import {Field} from 'formik'
import {Utility} from '../../utils/Utility'

/**
 * To set an initial value, simply pass the full form object with properly set initial
 * value of this radio input matching to props.name into Formik's initialValues prop.
 *
 * @param props
 * @constructor
 */
const RadioInput = props => {
  return (
    <div className="form-group radio-input">
      <label htmlFor={props.name}>{props.title}</label>
      {props.options.map((option, index) => {
        const id = `${props.id}-${index}`
        const formClassNames = Utility.classNames(['form-option', props.optionClassName])

        return (
          <Field key={index} name={props.name}>
            {({field, form}) => {
              return (
                <span className={formClassNames}>
                  <input
                    type="radio"
                    id={id}
                    name={props.name}
                    value={option.value}
                    onChange={() => {
                      const nextValue = option.value
                      form.setFieldValue(props.name, nextValue)
                    }}
                    checked={field.value === option.value}
                    disabled={props.disabled}
                  />
                  <label htmlFor={id}>{option.label}</label>
                </span>
              )
            }}
          </Field>
        )
      })}
    </div>
  )
}

export {RadioInput}
