import React from 'react'
import {Field} from 'formik'
import {DateTimePicker} from 'react-widgets'

// temporarily bypass improper typing
const DateTimePickerAsAny = DateTimePicker as any

interface IDateTimePickerInput {
  name: string
  title?: string
  label?: string
  // minimum date that is selectable
  min?: Date
}

/**
 * Supports a boolean (for example, on/off button) kind of checkbox handling.
 *
 * For multiple check options, see the CheckboxInput component.
 *
 * Requires only one option in props.options, and only regards label (disregards the object's value).
 * This will only set a true or false value.
 * e.g. options={[{label: 'Enabled'}]}
 *
 * @param props
 * @constructor
 */
const DateTimePickerInput = (props: IDateTimePickerInput) => {
  return (
    <div className="form-group datetime-picker-input">
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      <span className="form-input">
        <Field name={props.name}>
          {({field, form}) => {
            return (
              <DateTimePickerAsAny
                id={props.name}
                name={props.name}
                min={props.min}
                time={false}
                value={field.value}
                onCurrentDateChange={(date: Date) => {
                  form.setFieldValue(props.name, date)
                }}
                onChange={(date: Date) => {
                  // this handler gives either a full date with time or an empty value to indicate no date set
                  // called after onCurrentDateChange; if date is null, set; otherwise can disregard as setFieldValue already called
                  if (null === date) {
                    form.setFieldValue(props.name, null)
                  }
                }}
              />
            )
          }}
        </Field>
      </span>
      <span className="form-instruction">Leave blank to never expire access.</span>
    </div>
  )
}

export default DateTimePickerInput
