import React, {ReactElement} from 'react'
import './Page.scss'

interface IComponentProps {
  title?: string | ReactElement
  subtitle?: string | ReactElement
  className?: string
  // defaults to true
  grid?: boolean
}

const Page: React.FC<IComponentProps> = props => {
  let childrenClasses = ['page-container']
  if (props.grid || undefined === props.grid) {
    childrenClasses.push('grid')
  }

  return (
    <div className={(props.className || '') + ' ml-2 mt-3'}>
      <div className="page-header mb-3">
        {/*<Link to="/" className="return-to-landing">*/}
        {/*  <PolyIcon icon="directional_arrow_left_fill" size="lg"/>*/}
        {/*</Link>*/}
        {props.title && (
          <div className="title mt-1 mb-2">
            <div className="title-main">{props.title}</div>
            {props.subtitle && <div className="subtitle">{props.subtitle}</div>}
          </div>
        )}
      </div>
      <div className={childrenClasses.join(' ')}>{props.children}</div>
    </div>
  )
}

export {Page}
