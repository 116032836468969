import React from 'react'
import {UseQueryState} from 'urql'
import {get as _get} from 'lodash-es'

interface IComponentProps {
  respondTo: UseQueryState<any>
}

/**
 * Given first array element from urql's useQuery, output or handle error
 * from query.
 *
 * @param props
 * @constructor
 */
const GraphQLError: React.FC<IComponentProps> = props => {
  const error = _get(props, 'respondTo.error')

  if (!error) {
    return <></>
  }

  console.error('Remote query error', error)

  return <pre className="warning">Remote Query Error. Please try operation again or refresh page.</pre>
}

export {GraphQLError}
