import {applyMiddleware, createStore, combineReducers} from 'redux'
import {StateType} from 'typesafe-actions'
import {createBrowserHistory, History} from 'history'
import {routerMiddleware, RouterState, connectRouter} from 'connected-react-router'
import {composeWithDevTools} from 'redux-devtools-extension'
import {reducer as toastrReducer} from 'react-redux-toastr'

import {LayoutState} from './layout/types'
import {LayoutReducer} from './layout/reducers'
import {SettingsState} from './settings/types'
import {SettingsReducer} from './settings/reducers'
import {LoginState} from './login/types'
import {LoginReducer} from './login/reducers'
import {MenuState} from './menu/types'
import MenuReducer from './menu/reducers'

const initialState = {}

const createRootReducer = (history: History) =>
  combineReducers({
    login: LoginReducer,
    menu: MenuReducer,
    layout: LayoutReducer,
    settings: SettingsReducer,
    router: connectRouter(history),
    toastr: toastrReducer
  })

export const history = createBrowserHistory()
interface StateInterface {
  login: LoginState
  menu: MenuState
  layout: LayoutState
  router: RouterState
  settings: SettingsState
}

export type State = StateType<StateInterface>

export default function configureStore() {
  const rootReducer = createRootReducer(history)
  const middleware = [routerMiddleware(history)]
  const enhancers = [applyMiddleware(...middleware)]

  return createStore(rootReducer, initialState, composeWithDevTools(...enhancers))
}
