import React, {Component} from 'react'
import {
  assignWith as _assignWith,
  compact as _compact,
  isPlainObject as _isPlainObject,
  lowerCase as _lowerCase,
  pick as _pick,
  startCase as _startCase,
  toLower as _toLower,
  toUpper as _toUpper
} from 'lodash-es'
import {Utility} from '../../utils/Utility'
import {Role, MemberStatus} from '@mirror/dataplane/build/graphql'

class FormUtility extends Component {
  /**
   * Returns array of two values, [ isValid, validationWarning ] in the type of
   * [ boolean, ReactElement ]
   *
   * If isValid exists, will evaluate.  If it does not exist on props,
   * will consider availability of validationWarning (e.g. through formik's errors.myField)
   * to be evidence of field not being valid.
   *
   * Once all fields use formik, this logic can be simplified.
   *
   * @param props
   */
  static getValidationFromProps = (props: any) => {
    const invalidWarningElement = <p className="invalid-warning">{props.invalidWarning}</p>
    const emptyElement = <></>

    if ('isValid' in props) {
      const isValid = !!props.isValid
      return [isValid, isValid ? emptyElement : invalidWarningElement]
    }

    // if invalidWarning is defined and is not an empty string, indicates invalid
    if (props.invalidWarning) {
      return [false, invalidWarningElement]
    }

    // at this point, can only assume field is valid
    return [true, emptyElement]
  }

  /**
   * TODO build a test for this function, baseline should take
   * baseObject: { a: 5, b: 3, c: { sub: 6 } } and
   * overloadObject: { a: 9, b: null, c: { sub: 9, other: 10 } }
   * and return: { a: 9, b: 3, c: { sub: 9 } }
   *
   * Recursive function to take top-level properties in baseObject and assign
   * properties from overloadObject.  Avoid assigning if null value.
   *
   * @param baseObject
   * @param overloadObject
   */
  static formOverload = (baseObject, overloadObject) => {
    // iterate recursively and only assign object keys and sub-object keys found in baseObject
    const dataSourcePruned = _pick(overloadObject, Object.keys(baseObject))

    return _assignWith(baseObject, dataSourcePruned, (objValue, srcValue) => {
      if (_isPlainObject(objValue)) {
        return FormUtility.formOverload(objValue, srcValue)
      }

      // avoid assigning null values, prefer default set in form
      return null === srcValue ? objValue : srcValue
    })
  }

  /**
   * Given an enum, return a JS collection with options, e.g.
   * [ { label: 'Option 1', value: 'option_1' } ]
   *
   * @param jsEnum
   * @param filterValue - optional function that takes value, and if response is true includes it in the options returned
   * @param modifyLabel - optional function that takes the label and formats it;
   *                      for instance, the function could _startCase(_lowerCase(label))
   */
  static optionsFromEnum = (jsEnum: any, filterValue?: (string) => boolean, modifyLabel?: (string) => string) => {
    return _compact(
      Object.keys(jsEnum).map(key => {
        const option = {
          label: key,
          value: jsEnum[key]
        }

        // if filter function exists, and evaluates to false, don't include this option
        if (filterValue && !filterValue(option.value)) {
          return undefined
        }

        if (modifyLabel) {
          option.label = modifyLabel(option.label)
        }

        return option
      })
    )
  }

  static getRoleOptions(isOperations?: boolean) {
    const preventSuper = Utility.envVariable('REACT_APP_PREVENT_SUPER_ROLE_REGISTRATION', true, true)
    const preventStandard = Utility.envVariable('REACT_APP_PREVENT_STANDARD_ROLE_REGISTRATION', true, true)

    const filterValue = (value: string) => {
      // enforce SUPER role cannot be registered in UI (defaults to true)
      if (preventSuper && 'ops_super' === _toLower(value)) {
        return false
      }

      if (preventStandard && 'standard' === _toLower(value)) {
        return false
      }

      // non-operations accounts cannot use OPS_ roles
      if (!isOperations && _toUpper(value).startsWith('OPS_')) {
        return false
      }

      // operations account can only use OPS_ roles
      if (isOperations) {
        return _toUpper(value).startsWith('OPS_')
      }

      return true
    }

    return FormUtility.optionsFromEnum(Role, filterValue, FormUtility.formatRoleLabel)
  }

  /**
   * Friendly format for role, e.g. STANDARD -> Standard and OPS_ADMINISTRATOR -> Administrator
   *
   * @param label
   */
  static formatRoleLabel = (label: string) => {
    label = label.replace(/^OPS_/, '')

    return _startCase(_lowerCase(label))
  }

  static formatStatus = (status: MemberStatus | null): string => {
    return status ? _startCase(_lowerCase(status)) : ''
  }
}

export {FormUtility}
