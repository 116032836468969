import {createReducer} from 'typesafe-actions'
import {changeAuthState, changeUserData} from './actions'
import {UserData} from './types'
import {get as _get, cloneDeep as _cloneDeep} from 'lodash-es'

const defaultLoginState = {
  userData: {},
  authState: ''
}

const LoginReducer = createReducer(defaultLoginState)
  .handleAction(changeAuthState, (state, action) => ({
    ...state,
    authState: action.payload
  }))
  .handleAction(changeUserData, (state, action) => {
    // action.payload.data is CognitoUser; note in an error case it may be null
    const cognitoUser = _get(action, 'payload.data', undefined)
    const error = _get(action, 'payload.error')

    let name = ''
    let firstName = ''
    let lastName = ''
    let initials = ''
    let email = ''
    let role = ''

    if (cognitoUser) {
      firstName = name = _get<string>(cognitoUser, 'attributes.name', 'Operator').trim()
      email = _get<string>(cognitoUser, 'attributes.email', '').trim()
      initials = name.charAt(0).toUpperCase()
      // NOTE can comment next line to simulate no role (and no-access) page
      role = _get<string>(cognitoUser, 'signInUserSession.idToken.payload.role', '')

      if (name.includes(',')) {
        // check for comma -> name probably in form of `lastName, firstName`
        const parts = name.split(',')
        firstName = parts.slice(-1).join(' ').trim()
        lastName = parts.slice(0, -1).join(' ').trim()
      } else if (name.includes(' ')) {
        // check for space -> name probably in form of firstName lastName`
        const parts = name.split(' ')
        firstName = parts.slice(0, -1).join(' ').trim()
        lastName = parts.slice(-1).join(' ').trim()
      }

      initials = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
      name = [firstName, lastName].join(' ').trim()
    }

    const userData = {
      data: _cloneDeep(cognitoUser),
      error,
      name,
      email,
      firstName,
      lastName,
      initials,
      role
    } as UserData

    return {
      ...state,
      userData
    }
  })

export {LoginReducer}
