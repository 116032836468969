import React, {useState, useEffect, useCallback} from 'react'
import {get as _get, last as _last} from 'lodash-es'
import {useQuery} from '../graphql/graphqlClient'
import {Utility} from './Utility'

/**
 * Designed to be used with the PaginationControls component.
 */
const useDataPagination = (graphQL: any, variables: any, setVariables: any, path: string, cursorPath: string) => {
  const [data, setData] = useState([] as any)
  const [queryPause, setQueryPause] = useState(true)
  const [query, executeQuery] = useQuery(graphQL, variables, queryPause)
  const [cursorStack, setCursorStack] = useState([] as any)
  const [resultText, setResultText] = useState<string | undefined | any>()
  const hasNext = useCallback(() => {
    return Utility.hasNext(query, 'data.' + cursorPath)
  }, [query, cursorPath])

  useEffect(() => {
    // update this page's data
    setData(_get(query.data, path, []))
  }, [query.data, path])

  useEffect(() => {
    if (!data.length) {
      setResultText('')
      return
    }

    // re-tally current page and entry counts
    const currentPage = cursorStack.length
    const startResultNum = currentPage * variables.limit + 1
    let endResultNum = (currentPage + 1) * variables.limit
    const hasNextPage = hasNext()

    let aboutNum
    if (hasNextPage) {
      aboutNum = 'about ' + (endResultNum + variables.limit)
    } else {
      // no next page, determine actual result count
      endResultNum = startResultNum - 1 + data.length
      aboutNum = endResultNum
    }

    setResultText(
      <div className="search-results-status">
        {startResultNum}-{endResultNum} of {aboutNum} results
      </div>
    )
  }, [data, cursorStack, hasNext, variables.limit])

  // NOTE: this hook needs to track changes to filter (change of search parameters),
  // but at this time user cannot change page limit or sorting
  useEffect(() => {
    // reset cursor stack, which has result of resetting to first page
    setCursorStack([])

    // unpause querying after filter set for first time;
    // if no filter, remove query pause immediately
    // this avoids resource consuming empty queries
    if (!variables.filter || (variables.filter && Object.keys(variables.filter).length)) {
      if (queryPause) {
        setQueryPause(false)
      }
    }
  }, [variables.filter, variables.limit, queryPause])

  /**
   * @param cursor - if undefined, will get the first page of results
   * @param stackOperation
   */
  useEffect(() => {
    setVariables(variables => {
      return {
        ...variables,
        cursor: _last(cursorStack)
      }
    })
  }, [cursorStack, setVariables])

  return {
    // pass through from urql's useQuery response
    query,
    // pass through execute query from urql
    executeQuery,
    // array of data from current page
    data,
    hasPrevious: !!variables.cursor,
    hasNext: hasNext(),
    resultText,
    previousPage: () => {
      // pop, going to previous page and removing last item from stack
      // slice works because it returns a new array, and does not error for empty array
      setCursorStack(cursorStack => cursorStack.slice(0, -1))
    },
    nextPage: () => {
      const cursor = Utility.getNext(query, 'data.' + cursorPath)

      // ensure cursor is not already on stack (e.g. user pushing button quickly)
      if (cursor !== _last(cursorStack)) {
        // push, going to new page and pushing cursor on stack
        setCursorStack(cursorStack => [...cursorStack, cursor])
      }
    }
  }
}

export {useDataPagination}
