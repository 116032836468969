import './DeviceActivation.scss'
import React from 'react'
import {graphql} from '@mirror/dataplane'
import {SimpleEarth} from '../../components/Earth/SimpleEarth'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import {Utility} from '../../utils/Utility'
import {upperFirst as _upperFirst} from 'lodash-es'

interface IComponentProps {
  activation: graphql.DeviceQuery['device']['activations']['items'][0]
  index: number
}

const DeviceActivation: React.FC<IComponentProps> = props => {
  const labels = [
    props.activation.sourceIPMeta?.city,
    props.activation.sourceIPMeta?.regionName,
    props.activation.sourceIPMeta?.countryCode
  ]
  const label = labels.filter(text => !!text).join(', ')

  const markers = [
    {
      lat: props.activation.sourceIPMeta?.latitude,
      lon: props.activation.sourceIPMeta?.longitude,
      label
      // optionally add a small amount of text to a pin
      // @ts-ignore
      // pinLabel: props.activation.sourceIPGEO.regionCode
    }
  ]

  const data = [
    {label: 'Activated', output: _upperFirst(Utility.relativeFromUnixTime(props.activation.when))},
    {label: 'Software Version', output: props.activation.softwareVersion},
    {label: 'Hardware Version', output: props.activation.hardwareRevision},
    {label: 'User Agent', output: props.activation.userAgent},
    {label: 'Source IP', output: props.activation.sourceIP}
  ]

  const showMap =
    props.activation.sourceIPMeta?.latitude !== undefined && props.activation.sourceIPMeta?.longitude !== undefined

  return (
    <div className="activation">
      <PolyIcon title="Activation" icon="place" />
      {data.map((row, index) => {
        return (
          <div key={index}>
            {row.label && <label>{row.label}</label>}
            {row.output && <span>{row.output}</span>}
            {!row.output && <span>Unknown</span>}
          </div>
        )
      })}
      {showMap && <SimpleEarth index={props.index} markers={markers} zoomToFirstMarker={true} preventZoom={true} />}
    </div>
  )
}

export {DeviceActivation}
