import React from 'react'
import {FormUtility} from '../../components/Form/FormUtility'
import {TextLikeInput} from '../../components/Form/TextLikeInput'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {RemoteData} from '../../utils/RemoteData'
import {Utility} from '../../utils/Utility'

interface IComponentProps {
  id: string | undefined
  isNew: boolean
  saveButton: any
  accountQuery: any
  executeAccountQuery: any
  executeCreateAccountMutation: any
  executeUpdateAccountMutation: any
  // for now, pass props from parent so that history object is available for redirect
  // TODO there has to be a better way..!
  parentProps: any
}

// component to add or edit
const AccountProfileForm: React.FC<IComponentProps> = props => {
  // this object should match the graphql format as it is merged with it
  let profileForm = {
    // in formOverload function, this will update with actual account ID if this is an update
    id: undefined,
    name: '',
    oracleID: '',
    salesForceID: '',
    notes: ''
  }

  // inject (overload) values from remote data for form data
  if (!props.isNew && props.accountQuery.data) {
    profileForm = FormUtility.formOverload(profileForm, props.accountQuery.data.account)
  }

  return (
    <Formik
      initialValues={profileForm}
      onSubmit={async values => {
        const mutation = props.isNew ? props.executeCreateAccountMutation : props.executeUpdateAccountMutation
        RemoteData.handleCreateOrUpdateAccount(mutation, values)
          // refresh device data
          .then(id => {
            if (props.isNew) {
              Utility.redirect(undefined, props.parentProps, `/account/${id}`, 3000)
            } else {
              // refresh data
              props.executeAccountQuery()
            }
          })
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required().min(2, 'Enter at least 2 characters'),
        oracleID: Yup.string().max(50, 'Field is too long, please enter a maximum of 50 characters'),
        salesForceID: Yup.string().max(50, 'Field is too long, please enter a maximum of 50 characters')
      })}
    >
      {formikProps => {
        // currently unused: isSubmitting, setFieldValue
        const {errors, handleChange, handleBlur, handleSubmit} = formikProps
        const hasErrors = !!Object.keys(errors).length

        return (
          <Form onSubmit={handleSubmit}>
            <TextLikeInput
              name="name"
              title="Name*"
              className="mt-1"
              autoFocus
              isValid={!errors.name}
              invalidWarning={errors.name}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <TextLikeInput
              name="oracleID"
              type="text"
              title="Oracle ID"
              className="mt-1"
              isValid={!errors.oracleID}
              invalidWarning={errors.oracleID}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <TextLikeInput
              name="salesForceID"
              type="text"
              title="Salesforce ID"
              className="mt-1"
              isValid={!errors.salesForceID}
              invalidWarning={errors.salesForceID}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <TextLikeInput
              name="notes"
              type="text"
              title="Notes"
              className="mt-1"
              isValid={!errors.notes}
              invalidWarning={errors.notes}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {props.saveButton(hasErrors)}
          </Form>
        )
      }}
    </Formik>
  )
}

export {AccountProfileForm}
