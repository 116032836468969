import React from 'react'
import {Redirect} from 'react-router'
import {Auth} from 'aws-amplify'

const Logout: React.FC = () => {
  Auth.signOut().catch(e => {
    console.error('Error logging out', e)
  })

  return (
    <div>
      Logging out...
      <Redirect to="/login" />
    </div>
  )
}

export {Logout}
