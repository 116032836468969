import React from 'react'
import {get as _get} from 'lodash-es'
import {connect} from 'react-redux'
import {Redirect, Route, RouteComponentProps, Switch} from 'react-router'
import {withRouter} from 'react-router-dom'
import {UserData} from '../store/login/types'
import {Account} from './Accounts/Account'
import {Accounts} from './Accounts/Accounts'
import {DeviceRegister} from './Devices/DeviceRegister'
import {Devices} from './Devices/Devices'
import {Invitation} from './Invitation/Invitation'
import {NoAccess} from './NoAccess/NoAccess'
import {changeMenuVisible, changeProfileVisible} from '../store/layout/actions'
import {Device} from './Devices/Device'
import {Login} from './Login/Login'
import {Landing} from './Landing/Landing'
import {Logout} from './Logout/Logout'
import {Layout} from '../components/Layout/Layout'
import {Operations} from './Operations/Operations'
import {Tenants} from './Tenants/Tenants'

type IComponentProps = RouteComponentProps<any> & {
  authState: string
  userData: UserData
  history?: any
  changeMenuVisible?: any
  changeProfileVisible?: any
}

const RoutesBase: React.FC<IComponentProps> = props => {
  const {history} = props
  if (history) {
    history.listen((location, action) => {
      // on location change, close menus
      props.changeMenuVisible(false)
      props.changeProfileVisible(false)
    })

    if (hasHashPathRedirect(history)) {
      // a redirect is in progress; return early and do not continue to process
      // doing so may lead to a NotFound response, which would redirect to /Login
      // and disable InsecureRoutes from displaying
      return <></>
    }
  }

  const signedIn = 'signedIn' === _get(props, 'authState')
  const role = _get(props, 'userData.role')
  const noAccessPath = '/no-access'

  // Indication that this route must be accessed via signed in state
  const SecureRoute = ({component: Component, ...rest}) => (
    <Route
      {...rest}
      render={props => {
        if (signedIn) {
          // special case - if user signed in but has no role, redirect to noAccessPath
          if (noAccessPath === _get(props, 'match.path')) {
            // no longer need to go to noAccess page
            if (role) {
              return <Redirect to="/" />
            }
          } else if (!role) {
            // user is signed in but no role, redirect to noAccessPath
            return <Redirect to={noAccessPath} />
          }

          return <Component {...props} />
        } else {
          return <Redirect to="/login" />
        }
      }}
    />
  )

  // Indication that this route must be accessed via non-signed in state
  const InsecureRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (signedIn ? <Redirect to="/" /> : <Component {...props} />)} />
  )

  return (
    <Layout logoOnly={!signedIn} signedIn={signedIn}>
      {/* {'signedIn' && <Header />} */}
      <Switch>
        {/* Recommended path for log out */}
        {/*<Route exact={true} path="/logout" render={() => (*/}
        {/*  <Redirect to="/login"/>*/}
        {/*)}/>*/}

        {/* Redirects */}
        <Route exact={true} path="/settings" render={() => <Redirect to="/settings/profile" />} />

        {/* TODO comment out this dev helper */}
        {/*<Route exact={true} path="/" render={() => (*/}
        {/*  <Redirect to="/settings/mirror"/>*/}
        {/*)}/>*/}

        <SecureRoute exact={true} path="/" component={Landing} />
        <SecureRoute exact={true} path="/accounts" component={Accounts} />
        {/* /account/new to set up new account, /account/:id to edit existing account */}
        <SecureRoute exact={true} path="/account/:id" component={Account} />
        <SecureRoute exact={true} path="/devices" component={Devices} />
        <SecureRoute exact={true} path="/device/register" component={DeviceRegister} />
        <SecureRoute exact={true} path="/device/:id" component={Device} />
        <SecureRoute exact={true} path="/tenants" component={Tenants} />
        <SecureRoute exact={true} path="/operations" component={Operations} />
        {/* temporarily unused
        <SecureRoute exact={true} path="/settings/:page" component={Settings} />
         */}
        <SecureRoute exact={true} path="/operations" component={Operations} />
        <SecureRoute exact={true} path="/logout" component={Logout} />
        <SecureRoute exact={true} path={noAccessPath} component={NoAccess} />

        {/* Non-authenticated routes. */}
        <InsecureRoute exact={true} path="/login" component={Login} />
        {/* Route after invitation success, e.g. /invitation/accepted?name=Cody or /invitation/invalid?error=expired */}
        <InsecureRoute exact={true} path="/invitation/:result" component={Invitation} />

        {/* If nothing matches, avoid 404 page and redirect to top-level page,
        which will be either landing page or redirect to login */}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </Layout>
  )
}

/**
 * This would typically be handled by the server.  However, there is not a simple
 * way to have S3 and/or CloudFront serve an error document without an error status code,
 * AND have other routes on the same domain (e.g. /backend/api/*) have different
 * functioning.  While a Lambda@Edge function could resolve this, it was deemed simpler
 * to let React/JavaScript deal with the problem.
 *
 * Thus, a request to /login would spawn an S3 404 error and be intercepted by this code on S3
 * website hosting set up:
 *
 * <RoutingRules>
 *   <RoutingRule>
 *     <Condition>
 *       <KeyPrefixEquals/>
 *       <HttpErrorCodeReturnedEquals>403</HttpErrorCodeReturnedEquals>
 *     </Condition>
 *     <Redirect>
 *       <Protocol>https</Protocol>
 *       <HostName>dev.mirror.poly.com</HostName>
 *       <ReplaceKeyPrefixWith>#!/</ReplaceKeyPrefixWith>
 *     </Redirect>
 *   </RoutingRule>
 *   <RoutingRule>
 *     <Condition>
 *       <KeyPrefixEquals/>
 *       <HttpErrorCodeReturnedEquals>404</HttpErrorCodeReturnedEquals>
 *     </Condition>
 *     <Redirect>
 *       <Protocol>https</Protocol>
 *       <HostName>dev.mirror.poly.com</HostName>
 *       <ReplaceKeyPrefixWith>#!/</ReplaceKeyPrefixWith>
 *     </Redirect>
 *   </RoutingRule>
 * </RoutingRules>
 *
 * which then does a 301 redirect to /#!/login.
 * Then, this code function watches that request and displays it properly.  Since it uses a hashbang,
 * the index.html is served as the default document and not considered an error by S3.
 *
 * This function should only be executed once per page navigation or virtual route change.
 *
 * @help https://stackoverflow.com/a/39783111/3232832
 *
 * @param history
 */
const hasHashPathRedirect = (history): boolean => {
  // remove `#!/` prefix from redirected paths to restore original path
  // NOTE history.listen does not track hashbang requests
  const path = (/^#!(\/.*)$/.exec(window.location.hash) || [])[1]
  if (path) {
    history.replace(path)

    return true
  }

  return false
}

const mapStateToProps = (state: any) => {
  return {
    authState: state.login.authState,
    userData: state.login.userData
  }
}

const RoutesBaseWithRouter = withRouter(RoutesBase)

const Routes = connect(mapStateToProps, {changeMenuVisible, changeProfileVisible})(RoutesBaseWithRouter)

export {Routes}
