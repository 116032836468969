import React from 'react'
import './DeviceRegister.scss'
import {useDispatch} from 'react-redux'
import {useMutation} from 'urql'
import {graphql} from '@mirror/dataplane'
import {Card} from '../../components/Card/Card'
import {Page} from '../../components/Common/Page'
import {SelectInput} from '../../components/Form/SelectInput'
import {TextLikeInput} from '../../components/Form/TextLikeInput'
import {GraphQLError} from '../../elements/GraphQLError'
import {PolyIconOption} from '../../elements/PolyIcon/PolyIcon'
import {get as _get} from 'lodash-es'
import {changeBusy} from '../../store/layout/actions'
import {Notification} from '../../utils/Notification'
import {RemoteData} from '../../utils/RemoteData'
import {Utility} from '../../utils/Utility'
import * as Yup from 'yup'
import {Form, Formik} from 'formik'
import {RegisterDeviceInput, DeviceFamily} from '@mirror/dataplane/build/graphql'

interface Device {
  status: 'connected' | 'disconnected'
  macAddress: string
  model: string
  icon: PolyIconOption
  labelClassName?: string
}

interface IComponentProps {
  history: any
}

const DeviceRegister: React.FC<IComponentProps> = props => {
  const dispatch = useDispatch()
  const [registerDeviceMutation, executeRegisterDeviceMutation] = useMutation(graphql.mutations.registerDevice)

  const register = (input: any) => {
    executeRegisterDeviceMutation({input}).then(res => {
      // error handling & feedback managed by <GraphQLError... />
      let hasError = _get(res, 'error.message')
      if (!hasError && _get(res, 'data.registerDevice')) {
        const serial = _get(res, 'data.registerDevice.serial')
        Notification.success(`Save successful for Serial ${serial}.  Redirecting to device detail...`)

        const path = Utility.getDeviceLink(res.data.registerDevice)
        Utility.redirect(undefined, props, path, 5000)
      }
    })
  }

  const [deviceFamiliesQuery] = RemoteData.checkDeviceFamilyValues()
  const [deviceModelsQuery, modelOptions] = RemoteData.getDeviceModelOptions()

  dispatch(changeBusy(deviceModelsQuery.fetching || deviceFamiliesQuery.fetching || registerDeviceMutation.fetching))

  /**
   * Given a model, infer the device family for ease of user input.
   *
   * @param modelValue
   */
  const inferFamilyFromModel = (modelValue: string): DeviceFamily => {
    if (0 === modelValue.search(/^OBAN50/)) {
      return DeviceFamily.STUDIO
    }

    if (0 === modelValue.search(/^G62/)) {
      return DeviceFamily.STUDIO
    }

    if (0 === modelValue.search(/^X/)) {
      return DeviceFamily.STUDIO
    }

    if (0 === modelValue.search(/^G/)) {
      return DeviceFamily.GROUP
    }

    if (0 === modelValue.search(/^VVX/)) {
      return DeviceFamily.VVX
    }

    if (0 === modelValue.search(/^TRIO/)) {
      return DeviceFamily.TRIO
    }

    console.error('Could not infer family based on model, defaulting to empty string for model', modelValue)
    return '' as any
  }

  const initialValues = {
    // any used to prevent assuming a specific model on initial form state
    model: '' as any,
    serial: '' as string,
    mac: '' as string,
    family: '' as any
  } as RegisterDeviceInput

  return (
    <Page>
      {/* left side search column */}
      <div className="col-8 offset-2">
        {/* CONFIGURATION */}
        <Card className="device-section label-layout" index={3} headerText="New Device Registration" headerIcon="add_device">
          <GraphQLError respondTo={registerDeviceMutation} />
          <Formik
            initialValues={initialValues}
            onSubmit={async values => {
              register(values)
            }}
            validationSchema={Yup.object().shape({
              model: Yup.string().matches(/.+/).required('Select a model'),
              serial: Yup.string()
                .matches(/^[0-9A-Za-z:-]{14,20}$/i)
                .required(),
              mac: Yup.string()
                .matches(/^(?:[0-9A-Fa-f]{2}[:-]?){5}(?:[0-9A-Fa-f]{2})$/i)
                .required()
            })}
          >
            {formikProps => {
              const {values, errors, handleBlur, handleChange, handleSubmit} = formikProps
              const hasErrors = !!Object.keys(errors).length

              // infer family based on model
              if (values.model) {
                values.family = inferFamilyFromModel(values.model)
              }

              return (
                <Form onSubmit={handleSubmit}>
                  {/*{deviceFamiliesQuery.data && deviceModelsQuery.data && (*/}
                  <>
                    <SelectInput
                      className="col-3"
                      name="model"
                      title="Model"
                      options={modelOptions}
                      isValid={!errors.model}
                      invalidWarning={errors.model}
                      handleBlur={handleBlur}
                      // formerly regex .+
                      handleChange={handleChange}
                    />
                    {values.model && !values.family && <p className="warning">Unable to locate a valid device family for this model. Please notify the site administrator.</p>}
                    <TextLikeInput className="form-label-wide mt-1" name="serial" title="Serial" isValid={!errors.serial} invalidWarning="Enter a 10-20 character serial number." handleBlur={handleBlur} handleChange={handleChange} />
                    <TextLikeInput className="form-label-wide mt-1" name="mac" title="Mac" isValid={!errors.mac} invalidWarning="Enter a 12-character Mac address, with or without colons." handleBlur={handleBlur} handleChange={handleChange} />
                    {!registerDeviceMutation.fetching && (
                      <button className="button" type="submit" disabled={hasErrors}>
                        Register
                      </button>
                    )}
                    {registerDeviceMutation.fetching && (
                      <button className="button" type="submit" disabled={true}>
                        Registering...
                      </button>
                    )}
                  </>
                  {/*)}*/}
                </Form>
              )
            }}
          </Formik>
        </Card>
      </div>
    </Page>
  )
}

export {DeviceRegister}
