import {Auth} from 'aws-amplify'
import {get as _get} from 'lodash-es'
import React from 'react'
import {connect} from 'react-redux'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import {changeMenuVisible, changeProfileVisible} from '../../store/layout/actions'

interface IComponentProps {
  userData?: any
  hide?: boolean
  className?: string
  changeProfileVisible: any
  changeMenuVisible: any
  profileVisible?: boolean
  menuVisible?: boolean
}

const ProfileOverlayBase: React.FC<IComponentProps> = props => {
  if (props.hide === true) return null

  const logout = () => {
    // If logout successful, will be picked up by auth state listener and redirected
    Auth.signOut().catch(e => {
      console.error('Error signing out', e)
    })
  }

  const email = _get(props, 'userData.email', '')
  const name = _get(props, 'userData.name', '')
  // TODO real data for location
  const location = undefined

  const toggleProfileVisible = () => {
    props.changeProfileVisible(!props.profileVisible)
  }

  // TODO dev helper
  // props.changeProfileVisible(true)

  const overlayClasses = ['overlay', 'from-right']
  if (props.profileVisible) {
    overlayClasses.push('show')
  }

  return (
    <div id="profileOverlay" className={overlayClasses.join(' ')}>
      {/* Padding used to increase onClick area */}
      <div className="text-left pl-1 pt-1 pb-2" onClick={toggleProfileVisible}>
        <PolyIcon icon="directional_arrow_right_outline" size="md" />
      </div>
      <PolyIcon icon="person" size={150} />
      <h1 className="mb-1">{name}</h1>
      <div className="text-left ml-5">
        {email && (
          <p className="mb-25 profileData">
            <PolyIcon icon="mail_fill" size={16} className="mt-25 ml-5 mr-25" />
            {email}
          </p>
        )}
        {location && (
          <p className="mb-25 profileData">
            <PolyIcon icon="place" size={16} className="mt-25 ml-5 mr-25" />
            {location}
          </p>
        )}
      </div>
      <div className="buttons">
        {/* TODO reactivate when page built https://onecode.polycom-labs.com/mono/core/issues/53
        <Link to="/settings/profile">
          <button className="button wide info">Settings</button>
        </Link>
        */}
        <button className="button wide caution" onClick={logout}>
          <i />
          Log Out
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    userData: state.login.userData,
    authState: state.login.authState,
    profileVisible: state.layout.profileVisible
  }
}

const ProfileOverlay = connect(mapStateToProps, {changeProfileVisible, changeMenuVisible})(ProfileOverlayBase)

export {ProfileOverlay}
