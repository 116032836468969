import React from 'react'
import {Utility} from '../../utils/Utility'

interface ITabProps {
  // must be set, used for URL hash activation e.g. #api
  id: string
  onClick?: any
  tabIndex?: number
  isActive?: boolean
  labelClassName?: string
  label: string
}

export const Tab: React.FC<ITabProps> = props => {
  const classNames = Utility.classNames(['tab-link', props.labelClassName, props.isActive ? 'active' : ''])

  return (
    <li className="tab">
      <div
        id={props.id}
        className={classNames}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault()
          props.onClick(props.tabIndex)
        }}
      >
        {props.label}
      </div>
    </li>
  )
}
