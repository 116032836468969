import {createReducer} from 'typesafe-actions'
import {changeBusy, changeMenuVisible, changeProfileVisible} from './actions'

const defaultLoginState = {
  profileVisible: false,
  menuVisible: false
}

const LayoutReducer = createReducer(defaultLoginState)
  .handleAction(changeProfileVisible, (state, action) => ({
    ...state,
    profileVisible: action.payload,
    // ensure two overlays are not open at once
    menuVisible: false
  }))
  .handleAction(changeMenuVisible, (state, action) => {
    return {
      ...state,
      menuVisible: action.payload,
      // ensure two overlays are not open at once
      profileVisible: false
    }
  })
  .handleAction(changeBusy, (state, action) => {
    return {
      ...state,
      busy: action.payload
    }
  })

export {LayoutReducer}
